// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_PlayerPage__Jem6f{
    position: fixed;
    left: 0; top: 0; bottom: 0; right: 0;
    display: grid;
    grid-template-columns: 2fr 12fr;
    overflow: auto;
}
.styles_ContentContainer__Vng7o{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.styles_FormContainer__-Zm2\\+ {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.styles_ControlPanel__Q\\+EMv{
    width: 100%;
    display: flex;
    justify-content: start;
}
.styles_ControlPanel__Q\\+EMv > *{
    margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NewsPage/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO,EAAE,MAAM,EAAE,SAAS,EAAE,QAAQ;IACpC,aAAa;IACb,+BAA+B;IAC/B,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".PlayerPage{\n    position: fixed;\n    left: 0; top: 0; bottom: 0; right: 0;\n    display: grid;\n    grid-template-columns: 2fr 12fr;\n    overflow: auto;\n}\n.ContentContainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n.FormContainer {\n    max-width: 1200px;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n}\n.ControlPanel{\n    width: 100%;\n    display: flex;\n    justify-content: start;\n}\n.ControlPanel > *{\n    margin-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlayerPage": `styles_PlayerPage__Jem6f`,
	"ContentContainer": `styles_ContentContainer__Vng7o`,
	"FormContainer": `styles_FormContainer__-Zm2+`,
	"ControlPanel": `styles_ControlPanel__Q+EMv`
};
export default ___CSS_LOADER_EXPORT___;
