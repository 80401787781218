// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_NewsCreationForm__D3l-T{
    display: grid;
    grid-template-columns: 12fr;
}
.styles_NewsLanguage__lU9Q8{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.styles_InputFields__YiL7W {
    margin-top: 30px!important;
    margin-right: 30px!important;
    margin-left: 30px!important;
}
.styles_Textarea__6hzSh {
    margin-top: 30px!important;
    margin-right: 30px!important;
    margin-left: 30px!important;
    resize: none;
    height: 300px;
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 4px;
    border: 1px solid rgba(107, 103, 103, 0.541);

    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
}
.styles_Textarea__6hzSh:focus {
    outline: none;
    border: 2px solid rgba(8, 123, 255, 0.788);
}
.styles_CountryCityRow__uhvow{
    display: flex;
    justify-content: start;
}
.styles_CountryCityRow__uhvow > * {
    width: 100px;
}
.styles_WeightHeightRow__9zuTf{
    display: flex;
    justify-content: space-between;
}
.styles_WeightHeightRow__9zuTf > * {
    width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/news-creation-form/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,2BAA2B;AAC/B;AACA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,2BAA2B;IAC3B,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,4CAA4C;;IAE5C,uDAAuD;IACvD,eAAe;AACnB;AACA;IACI,aAAa;IACb,0CAA0C;AAC9C;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,YAAY;AAChB","sourcesContent":[".NewsCreationForm{\n    display: grid;\n    grid-template-columns: 12fr;\n}\n.NewsLanguage{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n}\n\n.InputFields {\n    margin-top: 30px!important;\n    margin-right: 30px!important;\n    margin-left: 30px!important;\n}\n.Textarea {\n    margin-top: 30px!important;\n    margin-right: 30px!important;\n    margin-left: 30px!important;\n    resize: none;\n    height: 300px;\n    padding-top: 16.5px;\n    padding-bottom: 16.5px;\n    padding-left: 14px;\n    padding-right: 14px;\n    border-radius: 4px;\n    border: 1px solid rgba(107, 103, 103, 0.541);\n\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-size: 16px;\n}\n.Textarea:focus {\n    outline: none;\n    border: 2px solid rgba(8, 123, 255, 0.788);\n}\n.CountryCityRow{\n    display: flex;\n    justify-content: start;\n}\n.CountryCityRow > * {\n    width: 100px;\n}\n.WeightHeightRow{\n    display: flex;\n    justify-content: space-between;\n}\n.WeightHeightRow > * {\n    width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewsCreationForm": `styles_NewsCreationForm__D3l-T`,
	"NewsLanguage": `styles_NewsLanguage__lU9Q8`,
	"InputFields": `styles_InputFields__YiL7W`,
	"Textarea": `styles_Textarea__6hzSh`,
	"CountryCityRow": `styles_CountryCityRow__uhvow`,
	"WeightHeightRow": `styles_WeightHeightRow__9zuTf`
};
export default ___CSS_LOADER_EXPORT___;
