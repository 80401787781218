// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora&family=Merriweather:wght@300&family=Oswald:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_AuthPage__fBGz\\+{
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.styles_AuthPageForm__gl\\+Ik{
    display: flex;
    flex-direction: column;
    width: 600px;
    height: -moz-fit-content;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 30px;
}

.styles_AuthPageForm__gl\\+Ik h1 {
    font-family: 'Oswald', sans-serif;
    margin: 0;
}

.styles_AuthPageForm__gl\\+Ik h3 {
    font-family: 'Lora', serif;
}

.styles_AuthPageForm__gl\\+Ik > .styles_TextInputField__DOVtT {
    background: aquamarine;
}

.styles_FormItem__hbzA3 {
    margin-top: 30px!important;
}
.styles_Footer__Ojejm {
    font-family: 'Oswald', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthPage/styles.module.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,MAAM,EAAE,SAAS,EAAE,OAAO,EAAE,QAAQ;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,iCAAiC;IACjC,SAAS;AACb;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,iCAAiC;AACrC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Lora&family=Merriweather:wght@300&family=Oswald:wght@500&display=swap');\n.AuthPage{\n    position: fixed;\n    top: 0; bottom: 0; left: 0; right: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.AuthPageForm{\n    display: flex;\n    flex-direction: column;\n    width: 600px;\n    height: fit-content;\n    border: 1px solid rgba(0, 0, 0, 0.3);\n    border-radius: 8px;\n    padding: 30px;\n}\n\n.AuthPageForm h1 {\n    font-family: 'Oswald', sans-serif;\n    margin: 0;\n}\n\n.AuthPageForm h3 {\n    font-family: 'Lora', serif;\n}\n\n.AuthPageForm > .TextInputField {\n    background: aquamarine;\n}\n\n.FormItem {\n    margin-top: 30px!important;\n}\n.Footer {\n    font-family: 'Oswald', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthPage": `styles_AuthPage__fBGz+`,
	"AuthPageForm": `styles_AuthPageForm__gl+Ik`,
	"TextInputField": `styles_TextInputField__DOVtT`,
	"FormItem": `styles_FormItem__hbzA3`,
	"Footer": `styles_Footer__Ojejm`
};
export default ___CSS_LOADER_EXPORT___;
