import LeftArrowIcon from '../../../../icons/leftArrow.svg'
import RightArrowIcon from '../../../../icons/rightArrow.svg'

import styles from './styles.module.css'
import { type ITableArrowsArgs } from './types'
import { IconButton } from '../../../../entities/IconButton'

export function TableArrows ({
  onRightClick,
  onLeftClick
}: ITableArrowsArgs) {
  return <div className={styles.TableArrows}>
        <IconButton icon={LeftArrowIcon} onClick={onLeftClick} />
        <IconButton icon={RightArrowIcon} onClick={onRightClick} />
        {/* <img src={LeftArrowIcon} alt={'no'} onClick={onLeftClick}/> */}
        {/* <img src={RightArrowIcon} alt={'no'} onClick={onRightClick}/> */}
    </div>
}
