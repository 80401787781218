import { ESearchQueryEquivalence, type TSearchQuery } from './types'

function searchStringIncludes (testValue: string, searchValue: string) {
  return testValue.includes(searchValue)
}

function searchStringEquals (testValue: string, searchValue: string) {
  return testValue === searchValue
}

function searchInString (testValue: string, searchValue: string) {
  return searchValue.includes(testValue)
}

const searchMethodChooser: any = {
  [ESearchQueryEquivalence.includes]: searchStringIncludes,
  [ESearchQueryEquivalence.equals]: searchStringEquals,
  [ESearchQueryEquivalence.inString]: searchInString
}

export function searchFilter (objectsArray: any, searchQuery: TSearchQuery[]) {
  if (!objectsArray || !objectsArray.filter) {
    return
  }

  return objectsArray.filter((object: any) => searchQuery.every((query: TSearchQuery) => 
    searchMethodChooser[query.equivalence](object[query.field], query.value)))
}
