import styles from './styles.module.css'
import { SearchBar } from './index'
import { type ISearchPanelArgs, type TSearchQuery } from './types'
import { useCallback, useState } from 'react'
import { type EColumnType, type TColumns } from '../data-grid/types'
import { type TSelectionVariant } from '../../entities/SelectionMenu/types'

/**
 *
 * @param columns
 * @param onQueryChange
 * @constructor
 *
 *
 * @todo: Выполнить удаление значения при изменении уже существующего поля, а не добавление нового
 * @todo: Выполнить блокирование выбора дублирующих условий
 */

export function SearchPanel ({
  columns,
  onQueryChange,
  className,
}: ISearchPanelArgs) {
  const [searchBarCount, setSearchBarCount] = useState(1)
  const [currentSearchQuery, setCurrentSearchQuery] = useState<TSearchQuery[]>([])

  const selectionVariants: Array<TSelectionVariant & { type: EColumnType }> = columns.map((col: TColumns) => {
    return {
      value: col.field || '',
      label: col.headerName || '',
      type: col.type
    }
  }).filter(variant => variant.value !== '' && variant.label !== '')

  const onPlus = useCallback(() => {
    setSearchBarCount(searchBarCount + 1)
  }, [searchBarCount])

  const onMinus = useCallback(() => {
    setSearchBarCount(searchBarCount - 1 || 1)
  }, [searchBarCount])

  const onSearchClick = useCallback((query: TSearchQuery, index: number) => {
    const newQuery = [...currentSearchQuery]
    newQuery[index] = query
    setCurrentSearchQuery(newQuery)
    onQueryChange(newQuery)
  }, [currentSearchQuery])

  const searchBars = Array.from(Array(searchBarCount)).map((_, index) => {
    return <SearchBar
            key={index}
            selectionVariants={selectionVariants}
            onPlusClick={onPlus}
            onMinusClick={onMinus}
            onSearchClick={(query: TSearchQuery) => { onSearchClick(query, index) }}
        />
  })

  return <div className={`${styles.SearchPanel} ${className}`}>
        {searchBars}
    </div>
}
