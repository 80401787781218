import { put, all } from 'redux-saga/effects'
import { PlayerManipulationService } from '../../../shared/player/services/player-manipulation-service'
import { type TPlayersArrayMessage } from '../../../pages/PlayersPage/types'
import { setCountPlayers, setPlayers } from '../slices/player'
import { setMessages } from '../slices/errors'
import { store } from '../index'
import { TournamentManipulationService } from '../../../shared/tournaments/services/tournament-manipulation-service'
import { type TTournamentsArrayMessage } from '../../../pages/TournamentsPage/types'
import { setChankTournaments, setCountTournaments, setTournaments } from '../slices/tournaments'
import { setEditableTournamentFields } from '../slices/editableTournament'
import { MatchManipulationService } from '../../../shared/match/match-manipulation-service'
import { setMatches } from '../slices/match'
import { setRole } from '../slices/login'
import { OperatorManipulationService } from '../../../shared/operator/operator-manipulation-service/operator-manipulation-service'
import { setCountOperators, setOperators } from '../slices/operators'
import { NewsManipulationService } from 'shared/news/services/news-manipulation-service'
import { TNewsArrayMessage } from 'pages/NewsPage/types'
import { setAllNews, setCountNews } from '../slices/news'

export const loadPlayersSaga = async () => {
  const dispatch = store.dispatch
  const playerManipulatorService = PlayerManipulationService()
  const {count} = await playerManipulatorService.getCountPlayers()
  put(dispatch(setCountPlayers(count)))
  const players: TPlayersArrayMessage = await playerManipulatorService.getPlayers(0, 5)
  if (players.errorMessage) {
    return put(dispatch(setMessages(players.errorMessage)))
  }
  if (players.players) {
    put(dispatch(setPlayers(players.players)))
  }
}

export const loadNewsSaga = async () => {
  const dispatch = store.dispatch
  const newsManipulatorService = NewsManipulationService()
  const count = await newsManipulatorService.getCountNews()
  put(dispatch(setCountNews(count.count)))
  const allNews: TNewsArrayMessage = await newsManipulatorService.getNewsPage(0, 5)
  if (allNews.errorMessage) {
    return put(dispatch(setMessages(allNews.errorMessage)))
  }
  if (allNews.allNews) {
    put(dispatch(setAllNews(allNews.allNews)))
  }
}

// export const loadMatchesSaga = async () => {
//   const dispatch = store.dispatch
//   const matchManipulatorService = MatchManipulationService()
//   const matches: any = await matchManipulatorService.takeWholeMaches()
//   if (matches.errorMessage) {
//     return put(dispatch(setMessages(matches.errorMessage)))
//   }
//   put(dispatch(setMatches(matches.matches)))
// }

export const loadEditableTournamentFromLocalStorage = async () => {
  const dispatch = store.dispatch
  const localTournament = localStorage.getItem('editableTournament')
  if (!localTournament) {
    return
  }

  put(dispatch(setEditableTournamentFields(JSON.parse(localTournament))))
}

export const loadTournamentsSaga = async () => {
  const dispatch = store.dispatch
  const tournamentManipulationService = TournamentManipulationService()
  const count = await tournamentManipulationService.getCountTournaments()
  put(dispatch(setCountTournaments(count.count)))
  const tournaments: TTournamentsArrayMessage = await tournamentManipulationService.getTournaments(0, 5)
  if (tournaments.errorMessage) {
    return put(dispatch(setMessages(tournaments.errorMessage)))
  }
  if (tournaments.tournaments) {
    put(dispatch(setTournaments(tournaments.tournaments)))
  }
} 

export const loadAdminRoleSaga = async () => {
  const dispatch = store.dispatch
  const role = localStorage.getItem('role')
  dispatch(setRole(role))
}

export const loadOperatorsList = async () => {
  const dispatch = store.dispatch
  const operatorManipulationService = OperatorManipulationService()
  const count = await operatorManipulationService.getCountOperatores()
  put(dispatch(setCountOperators(count.count)))
  const {errorMessage, operators}: any = await operatorManipulationService.getOperatores(0, 5)
  return dispatch(setOperators(operators || []))
}

export function * rootSaga () {
  yield all([
    loadNewsSaga(),
    loadAdminRoleSaga(),
    loadPlayersSaga(),
    // loadMatchesSaga(),
    loadTournamentsSaga(),
    loadEditableTournamentFromLocalStorage(),
    loadOperatorsList(),
  ])
}
