import { TextField } from '@mui/material'
import { type ITextInputFieldArgs } from './types'
import { SyntheticEvent, useEffect, useState } from 'react'

export const TextInputField = ({ 
    id, 
    label, 
    onChange, 
    type, 
    className, 
    value, 
    validation = () => true,
    errorText,
}: ITextInputFieldArgs) =>{

    const [validationResult, setValidationResult] = useState(validation(value || ''))

    useEffect(() => {
        setValidationResult(validation(value || ''))
    }, [value, validation])

    const localOnChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
        
        if(!validation){
            onChange(e)
            return
        }

        const validationResult = validation(e.currentTarget.value)
      
        if(!validationResult){
            setValidationResult(false)
            onChange(e)
            return
        }

        onChange(e)
    }

    return <TextField
        className={className}
        id={id}
        label={label}
        type={type}
        variant={'outlined'}
        value={value || (type === 'date' ? '0000-00-00' : '')}
        onChange={localOnChange}
        helperText={validationResult ? '' : errorText}
        error={!validationResult}
    />
}
