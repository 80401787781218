import styles from './styles.module.css'
import { type SyntheticEvent, useCallback, useState } from 'react'
import { type TPlayer } from '../../../pages/PlayersPage/types'
import { TeamLine } from './components/TeamLine'
import { IntermediateLine } from './components/IntermediateLine'
import { useDispatch, useSelector } from 'react-redux'
import { changeOnlineStatus, clearMatch, pushToIdOperator, removePlayerFromMatch, setActivePlayers, setAdittionRet, setEditableTournamentFields, setInternalPlayers, setMatchDatetime, setSelectionParameters, setTours, takeSelectedCard, updateSelectedCard } from '../../../app/store/slices/editableTournament'
import { type RootState } from '../../../app/store'
import { type TMatch } from '../../../pages/TournamentsPage/types'
import { IsAdmin, IsOperator, userIsOperator } from '../../../shared/checkUser'
import { TournamentManipulationService } from '../../../shared/tournaments/services/tournament-manipulation-service'
import { Alert, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getGMTDate, fromGMTDate } from 'shared/procedures'
import { FINAL_ID, HEMIFINAL_ID, FOR_SEVENTH_PLACE, FOR_FIFTH_PLACE, } from 'shared/constants'
import ChangeTime from '../../../icons/time-change.png'

import DeleteIcon from 'icons/remove.svg'

interface TTeamNames {
  flag: string
  name: string
}

export interface TTeamScore {
  score: number | undefined
  tiebreak?: number | undefined
}

interface ITournamentCardArgs {
  match: TMatch
  selectable?: boolean
  selectedMatchNumber?: number
  cardId: number
  tourId: number
  isTopGrid?: boolean,
  isBottomGrid?: boolean,
}

type TSelectableParameters = {
  tourId: number | undefined, 
  cardId: number | undefined, 
  teamId: number | undefined, 
}

export function RemoveButton({onRemove, className}: any){
  return <img src={DeleteIcon} className={styles.RemoveIcon + ' ' + className } onClick={onRemove}/>
}

export function TournamentCard ({
  match,
  selectable,
  cardId,
  tourId,
  selectedMatchNumber,
  isTopGrid = false,
  isBottomGrid = false,
}: ITournamentCardArgs) {

  const [operatorId, setOperatorId] = useState('')
  const [matchId, setMatchId] = useState('')
  const [changeStartMatch, setСhangeStartMatch] = useState('')
  const [changeStartMatchStatus, setСhangeStartMatchStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const [schedulerIsOpen, setSchedulerIsOpen] = useState(false)

  const isEllimitationTournament = useSelector((state: RootState) => state.editableTournamentSlice.isEllimination)

  const selectableParameters: TSelectableParameters = useSelector((state: RootState) => state.editableTournamentSlice.selectableParameters)
  const activePlayers: TPlayer[] = useSelector((state: RootState) => state.editableTournamentSlice.activePlayers)
  const internalPlayers: TPlayer[] = useSelector((state: RootState) => state.editableTournamentSlice.internalPlayers)
  const selected = (() => {
    if(isEllimitationTournament){

      return selectableParameters.tourId === tourId && selectableParameters.cardId === cardId
    }


    if(selectableParameters.cardId === FINAL_ID && cardId === FINAL_ID){
      return true
    }

    if(selectableParameters.cardId === HEMIFINAL_ID && cardId === HEMIFINAL_ID){
      return true
    }

    if(selectableParameters.cardId === FOR_SEVENTH_PLACE && cardId === FOR_SEVENTH_PLACE){
      return true
    }

    if(selectableParameters.cardId === FOR_FIFTH_PLACE && cardId === FOR_FIFTH_PLACE){
      return true
    }

    return selectableParameters.tourId === tourId && selectableParameters.cardId === cardId &&
          ((selectableParameters.isTopGrid && isTopGrid) ||  
          (selectableParameters.isBottomGrid && isBottomGrid))
                   
  })()
  const isOperatorChecking = userIsOperator()

  const { team1Score, team2Score, team1Names, team2Names } = match

  const dispatch = useDispatch()
  const editableTournament: any = useSelector((state: RootState) => state.editableTournamentSlice)

  /**
     * Online indicator
     */

  const switchCardOnlineStatement = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(changeOnlineStatus({
      match,
      cardId,
      tourId,
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    } as any))
  }

  /// ///////////////////////////////////////

  const onTopTeamLineClick = useCallback((e: SyntheticEvent<HTMLDivElement, Event>) => {
    dispatch(setSelectionParameters({
      tourId, cardId, teamId: 0, 
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    }))
    e.stopPropagation()
  }, [tourId, cardId])

  const onBottomTeamLineClick = useCallback((e: SyntheticEvent<HTMLDivElement, Event>) => {
    dispatch(setSelectionParameters({
      tourId, cardId, teamId: 1, 
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    }))
    e.stopPropagation()
  }, [tourId, cardId])

  const setDatetime = (e: any) => dispatch(setMatchDatetime({
    datetime: new Date(e.target.value).getTime(), // getGMTDate(e.target.value),
    tourId,
    cardId,
    isTopGrid,
    isBottomGrid,
    isEllimination: isEllimitationTournament,
  }))

  const setAdditionRet = (numberTeam: number) => dispatch(setAdittionRet({
    teamId: numberTeam,
    tourId,
    cardId,
    isTopGrid,
    isBottomGrid,
    isEllimination: isEllimitationTournament,
  }))

  const operatorCardSelection = (e: SyntheticEvent) => {
    // if(!isOperatorChecking){
    //   return
    // }

    dispatch(setSelectionParameters({
      tourId, cardId, teamId: undefined, 
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    }))
  }

  const clearCard = () => {
    dispatch(clearMatch({
      tourId,
      cardId,
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    }))
  }

  const onPlayerRemovingRequest = (removingPlayer: TPlayer) => {
    const playerInActive = activePlayers.findIndex((p: TPlayer) => p.id === removingPlayer.id)
    
    dispatch(removePlayerFromMatch({
      playerId: removingPlayer.id,
      cardId,
      tourId,
      isTopGrid,
      isBottomGrid,
      isEllimination: isEllimitationTournament,
    }))
    

    if(playerInActive === -1){
      return
    }

    dispatch(setActivePlayers(activePlayers.filter((p: TPlayer) => p.id !== removingPlayer.id)))
    dispatch(setInternalPlayers([...internalPlayers, removingPlayer]))
  }

  const onUpdateOperatorId = async () => {

    const card = JSON.parse(JSON.stringify(takeSelectedCard(editableTournament, editableTournament.selectableParameters)))
    card.operatorId = Number(operatorId)
    const newEditableTournament = updateSelectedCard(editableTournament, card, editableTournament.selectableParameters)

    dispatch(setEditableTournamentFields(newEditableTournament))
    setResultMessage(`ID оператора добавлен`)
    setTimeout(() => setResultMessage(''), 5000)
    setOperatorId('')
  }

  const onUpdateMatchId = () => {
    const card = JSON.parse(JSON.stringify(takeSelectedCard(editableTournament, editableTournament.selectableParameters)))
    card.hash = Number(matchId)
    const newEditableTournament = updateSelectedCard(editableTournament, card, editableTournament.selectableParameters, true)

    dispatch(setEditableTournamentFields(newEditableTournament))
    setResultMessage(`ID матча добавлен`)
    setTimeout(() => setResultMessage(''), 5000)
    setMatchId('')
  }

  const onUpdateChangeStartMatch = () => {
    const card = JSON.parse(JSON.stringify(takeSelectedCard(editableTournament, editableTournament.selectableParameters)))
    card.changeStartMatch = changeStartMatch
    const newEditableTournament = updateSelectedCard(editableTournament, card, editableTournament.selectableParameters)

    dispatch(setEditableTournamentFields(newEditableTournament))
    setResultMessage(`Информация о переносе матча добавлена`)
    setTimeout(() => setResultMessage(''), 5000)
    setСhangeStartMatch('')
  }

  const setAdditionPlayer = (playerIndex: number, addition: string) => {
    const card = JSON.parse(JSON.stringify(takeSelectedCard(editableTournament, editableTournament.selectableParameters)))
    if (playerIndex === 0) {
      card.team1Addition[0] === "ret" || "cup" ? card.team1Addition.unshift(addition) : card.team1Addition[0] = addition
    }
    if (playerIndex === 1) {
      card.team2Addition[0] === "ret" || "cup" ? card.team2Addition.unshift(addition) : card.team2Addition[0] = addition
    }
    const newEditableTournament = updateSelectedCard(editableTournament, card, editableTournament.selectableParameters)

    dispatch(setEditableTournamentFields(newEditableTournament))
    setResultMessage(`${addition} выставлен игроку № ${playerIndex + 1}`)
    setTimeout(() => setResultMessage(''), 5000)
  }

  const formatDate = (date: Date) => date.toLocaleString()
  const manualGridVariants: string[] = [
    '', 'WC', 'LL', 'Q', '1', '2', '3', '4'
]

  return <div 
          className={styles.containerCart}
          onClick={operatorCardSelection}
          onMouseEnter={operatorCardSelection}
          >
    <div
        className={`${styles.TorunamentGreedCard} ${selectable && styles.Selectable} ${selected && styles.Selected}`}
    >

        <IsAdmin>
          <RemoveButton onRemove={clearCard} className={styles.DropIcon}/>
          <div className={styles.SheduleMark}>
            <input className={styles.DateTime} type='datetime-local' onChange={setDatetime} />
            <img className={match.changeStartMatch ? styles.ChangeTime + ' ' + styles.ChangeTimeActive : styles.ChangeTime} alt={''} src={ChangeTime} onClick={() => setСhangeStartMatchStatus(!changeStartMatchStatus)}/>  
          </div>
        </IsAdmin>
          <div className={styles.OnlineIndicator} onClick={switchCardOnlineStatement}>
              {formatDate(new Date(parseInt(match.datetime || 0))) || ''}
          </div>
              {/* Name1Playe + Score */}
              <TeamLine
                  selected={selected || false}
                  teamSelected={selected && selectableParameters.teamId === 0}
                  onTeamLineClick={onTopTeamLineClick}
                  team={team1Names}
                  onPlayerDblClick={onPlayerRemovingRequest}
                  teamScore={team1Score}
                  addition={match.team1Addition}
                  onRetClick={() => setAdditionRet(0)}
              />
              {/* Sets */}
              <IntermediateLine
                  scoreLength={team1Score?.length || 1}
                  selected={selected || false}
                  selectedMatchNumber={selectedMatchNumber}
              />
              {/* Name2Playe + Score */}
              <TeamLine
                  selected={selected || false}
                  teamSelected={selected && selectableParameters.teamId === 1}
                  onTeamLineClick={onBottomTeamLineClick}
                  team={team2Names}
                  onPlayerDblClick={onPlayerRemovingRequest}
                  teamScore={team2Score}
                  addition={match.team2Addition}
                  onRetClick={() => setAdditionRet(1)}
              />
        <div>
          <div className={styles.operatorId}>
                {match.operatorId ? 'Id оператора: ' + match.operatorId : 'Id оператора: '}
          </div>
          <div className={styles.matchId}>
                {match.hash ? 'Id матча: ' + match.hash : 'Id матча: '}
          </div> 
        </div>
        </div>
        {schedulerIsOpen && <div className={styles.OpenScheduler}>
          <input className={styles.DateTime} type='datetime-local' onChange={setDatetime} />  
        </div>}
        {/* <IsOperator> */}
            {selected && <div className={styles.InputsId}>
              <div>
                <input onChange={(event) => { setOperatorId(event.target.value) }} placeholder="Введите ID оператора"/>
                <button onClick={ onUpdateOperatorId }>{match.operatorId && !operatorId ? 'Очистить' : 'Добавить'}</button>
              </div>
              <div>
                <input onChange={(event) => { setMatchId(event.target.value) }} placeholder="Введите ID матча"/>
                <button onClick={ onUpdateMatchId }>{match.hash && !matchId ? 'Очистить' : 'Добавить'}</button>
              </div>
            </div>}

            {selected && changeStartMatchStatus && <div className={styles.ChangeStartMatch}>
              <textarea onChange={(event) => { setСhangeStartMatch(event.target.value) }} placeholder={ match.changeStartMatch || "Матч перенесен на **:**:**/2 часа"}/>
              <button onClick={ onUpdateChangeStartMatch }>{match.changeStartMatch && !changeStartMatch ? 'Очистить' : 'Добавить'}</button>
            </div>}
            <div className={styles.InputsPlayers}>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={match.team1Addition[0]}
                label="Age"
                onChange={(event: SelectChangeEvent) => {
                  setAdditionPlayer(0, event.target.value)
                }}
              >
                {manualGridVariants.map((val: string) =>  <MenuItem value={val}>{val}</MenuItem>  )}
              </Select>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={match.team2Addition[0]}
                label="Age"
                onChange={(event: SelectChangeEvent) => {
                  setAdditionPlayer(1, event.target.value)
                }}
              >
                {manualGridVariants.map((val: string) =>  <MenuItem value={val}>{val}</MenuItem>  )}
              </Select>
            </div>
        {/* </IsOperator> */}
        <div className={styles.responseMessage}>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
        </div>
</div>
}
