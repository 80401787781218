import styles from '../tournament-grid-editor/styles.module.css'
import { TournamentCard } from '../tournament-card'
import { connect } from 'react-redux'
import { type RootState } from '../../../app/store'
import { TMatch } from '../../../pages/TournamentsPage/types'
import { EMainTournamentTabNames } from '../tournament-grid-tours-tabs'
import { 
  FINAL_ID,
  HEMIFINAL_ID,
  FOR_SEVENTH_PLACE,
  FOR_FIFTH_PLACE,
  THIRD_TOP_GRID_ID, 
  SECOND_TOP_GRID_ID, 
  FIRST_TOP_GRID_ID 
} from 'shared/constants' 

interface ITournamentTourCardsListArgs {
  selectedIndex: number | undefined
  tours: TMatch[][]
  isEllimination: boolean
  upperGrid: TMatch[][]
  bottomGrid: TMatch[][]
  forSeventPlace: TMatch
  forFifthPlace: TMatch
  hemiFinal: TMatch
  final: TMatch
}

const mapStateToProps = (state: RootState) => {
  return {
    tours: state.editableTournamentSlice.tours,
    selectedIndex: state.editableTournamentSlice.selectableParameters.tourId,
    isEllimination: state.editableTournamentSlice.isEllimination,
    upperGrid: state.editableTournamentSlice.upperGrid,
    bottomGrid: state.editableTournamentSlice.bottomGrid,
    forSeventPlace: state.editableTournamentSlice.forSeventPlace,
    forFifthPlace: state.editableTournamentSlice.forFifthPlace,
    hemiFinal: state.editableTournamentSlice.hemiFinal,
    final: state.editableTournamentSlice.final,
  }
}

export const TournamentTourCardsList = connect(mapStateToProps)(({
  tours,
  selectedIndex = 0,
  isEllimination,
  upperGrid,
  bottomGrid,
  forSeventPlace,
  forFifthPlace,
  hemiFinal,
  final,
}: ITournamentTourCardsListArgs) => {
  
  // карточки
  if(isEllimination){
    if (tours.length === 0) {
      return <div className={styles.Cards}></div>
    }

    return <div className={styles.Cards}>
        {tours[selectedIndex].map((match: any, cid: number) => <TournamentCard
            key={cid}
            cardId={cid}
            tourId={selectedIndex}
            match={match}
            selectable={true}
        />
        )}
    </div>
  }
  
  if( selectedIndex === EMainTournamentTabNames.FIRST ){
    return <div className={styles.Cards}>
      <div>Верхняя сетка</div>
      {upperGrid[FIRST_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
          key={cid}
          cardId={cid}
          tourId={selectedIndex}
          match={match}
          selectable={true}
          isTopGrid
      />)}
      
      <div>Нижняя сетка</div>
      {bottomGrid[FIRST_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
          key={cid}
          cardId={cid}
          tourId={selectedIndex}
          match={match}
          selectable={true}
          isBottomGrid
      />)}
      <div>Матч за 7е место</div>
      <TournamentCard
            cardId={FOR_SEVENTH_PLACE}
            tourId={selectedIndex}
            match={forSeventPlace}
            selectable={true}
        />
      </div>
  }else if(selectedIndex === EMainTournamentTabNames.SECOND){
    return <div className={styles.Cards}>
      <div>Верхняя сетка</div>
      {upperGrid[SECOND_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
          key={cid}
          cardId={cid}
          tourId={selectedIndex}
          match={match}
          selectable={true}
          isTopGrid
      />)}
      
      <div>Нижняя сетка</div>
      {bottomGrid[SECOND_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
          key={cid}
          cardId={cid}
          tourId={selectedIndex}
          match={match}
          selectable={true}
          isBottomGrid
      />)}
      <div>Матч за 5е место</div>
      <TournamentCard
            cardId={FOR_FIFTH_PLACE}
            tourId={selectedIndex}
            match={forFifthPlace}
            selectable={true}
        />
      </div>
  }else if(selectedIndex === EMainTournamentTabNames.THIRD){
    return <div className={styles.Cards}>
    <div>Верхняя сетка</div>
    {upperGrid[THIRD_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
        key={cid}
        cardId={cid}
        tourId={selectedIndex}
        match={match}
        selectable={true}
        isTopGrid
    />)}
    
    <div>Нижняя сетка</div>
    {bottomGrid[THIRD_TOP_GRID_ID].map((match: any, cid: number) => <TournamentCard
        key={cid}
        cardId={cid}
        tourId={selectedIndex}
        match={match}
        selectable={true}
        isBottomGrid
    />)}
    </div>
  }else if(selectedIndex === EMainTournamentTabNames.HEMIFINAL){
    return <div className={styles.Cards}>
        <TournamentCard
            cardId={HEMIFINAL_ID}
            tourId={selectedIndex}
            match={hemiFinal}
            selectable={true}
        />
    </div>
  }
  
  return <div className={styles.Cards}>
        <TournamentCard
            cardId={FINAL_ID}
            tourId={selectedIndex}
            match={final}
            selectable={true}
        />
    </div>
})
