import { RootState } from "../../../app/store";
import { TTour } from "../../../pages/TournamentsPage/types";
import {connect} from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch } from 'react-redux'
import { manualChangeGridLastSize, manualChangeGridSize, setSelectionParameters, switchManualGridEdition } from "../../../app/store/slices/editableTournament";
import { StarRateSharp } from "@mui/icons-material";
import { SyntheticEvent, useEffect } from "react";

export type TTournamentGridParamsProps = {
    manualGridEdition: boolean,
    gridFirstSize: number,
    gridLastSize: number,
    tours: TTour[],
    switchManualGridEdition: () => any,
    manualChangeGridSize: (gridSize: number) => any,
    manualChangeGridLastSize: (gridSize: number) => any,
}

const mapStateToProps = (state: RootState) => ({
    manualGridEdition: state.editableTournamentSlice.manualGridEdition,
    gridFirstSize: state.editableTournamentSlice.gridFirstSize,
    gridLastSize: state.editableTournamentSlice.gridLastSize,
    tours: state.editableTournamentSlice.tours,
    isEllimination: state.editableTournamentSlice.isEllimination,
})

const mapDispatchToProps = (dispatch: ReturnType<typeof useDispatch>) => ({
    switchManualGridEdition: () => dispatch(switchManualGridEdition(undefined)),
    manualChangeGridSize: (newGridSize: number) => dispatch(manualChangeGridSize(newGridSize)),
    manualChangeGridLastSize: (newGridSize: number) => dispatch(manualChangeGridLastSize(newGridSize))
})

const manualGridVariants: number[] = [
    2, 4, 8, 16, 32, 64, 128, 256, 512
]

export const TournamentGridParams = connect(mapStateToProps, mapDispatchToProps)(({
    tours, 
    gridFirstSize, 
    gridLastSize, 
    manualGridEdition, 
    switchManualGridEdition,
    manualChangeGridSize,
    manualChangeGridLastSize,
    isEllimination,
}: TTournamentGridParamsProps) => {
    const dispatch = useDispatch()

    /* useEffect(() => {
      if(!isEllimination){
          
      }
      }, []) */

    return <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
            <FormControlLabel
                value="start"
                control={<Switch color="primary" checked={manualGridEdition} onChange={() => switchManualGridEdition()}/>}
                label="Ручной режим установки параметров турнирной сетки"
                labelPlacement="start"
            />
        </FormGroup>
        <FormGroup aria-label="position" row>
            <FormControlLabel
                value={String(gridFirstSize)}
                control={
                    <Select
                        sx={{
                            marginLeft: 3, 
                        }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={String(gridFirstSize)}
                        label="Age"
                        onChange={(event: SelectChangeEvent) => {
                            if(parseInt(event.target.value) < gridLastSize) {
                                return
                            }
                            manualChangeGridSize(parseInt(event.target.value)),
                            dispatch(setSelectionParameters({
                                tourId: 0
                            }))
                        }}
                    >
                        {manualGridVariants.map((val: number) =>  <MenuItem value={val}>{val}</MenuItem>  )}
                    </Select>
                }
                label="Ручная установка количество матчей в первом раунде"
                labelPlacement="start"
            />
            {/* <FormHelperText>Ручная установка количество матчей в первом раунде</FormHelperText> */}
        </FormGroup>
        <FormGroup aria-label="position" row>
            <FormControlLabel
                value={String(gridLastSize)}
                control={
                    <Select
                        sx={{
                            marginTop: 1,
                            marginLeft: 9.4, 
                        }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={String(gridLastSize)}
                        label="Age"
                        onChange={(event: SelectChangeEvent) => {
                            if(parseInt(event.target.value) > gridFirstSize) {
                                return
                            }
                            manualChangeGridLastSize(parseInt(event.target.value)),
                            dispatch(setSelectionParameters({
                                tourId: 0
                            }))
                        }}
                    >
                        {manualGridVariants.map((val: number) =>  <MenuItem value={val}>{val}</MenuItem>  )}
                    </Select>
                }
                label="Ручная установка количество выходных матчей"
                labelPlacement="start"
            />
            {/* <FormHelperText>Ручная установка количество матчей в первом раунде</FormHelperText> */}
        </FormGroup>
    </FormControl>
})
