import { type IDataGridArgs, TActiveIndices, type TInternalRows } from './types'
import { TableHeader } from './components/TableHeader/TableHeader'
import { TableContainer, Table, Paper, NativeSelect } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { TableRows } from './components/TableRows/TableRows'
import { TableArrows } from './components/TableArrows/TableArrows'
import { PerPageSelector } from './components/PerPageSelector/PerPageSelector'
import { PerPageCount } from './components/PerPageCount/PerPageCount'
import { makeInternalRows, takeJustSelectedRows } from './procedures'
import { PlayerManipulationService } from 'shared/player/services/player-manipulation-service'

/**
 *
 * @param rows
 * @param columns
 * @constructor
 *
 * @todo: Выполнить выделение строк                                                     [X]
 * @todo: Добавить функции редактирования строк                                         [X]
 * @todo: Выполнить фильтрация по значениям                                             [ ]
 * @todo: Выполнить поиск по множеству значений                                         [ ]
 * @todo: Выполнить всплывающие окна настройки поиска                                   [ ]
 * @todo: Выполнить сокращение длинных строк                                            [ ]
 * @todo: Выполнить редактирование на месте и множественное сохранение с удалением      [ ]
 * @todo: Выполнить установку размеров для ячеек строк таблицы                          [X]
 * @todo: Выполнить типы данных для полей в таблице (Дата, строка, число, ...)          [ ]
 * @todo: Выполнить возможность проброса валидации полей при редактировании             [ ]
 */

const tableControlPanelStyle: any = {
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 10%',
  minWidth: 'fit-content',
  padding: '15px',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  lineHeight: '20.02px',
  borderCollapse: 'collapse',
  borderSpacing: 0
}

export function DataGrid ({
  onChange,
  nextRows,
  rows = [],
  count,
  columns,
  sort = false,
  onRowChange = () => {},
  onRowDelete = () => {},
  onSelectionChange = () => {},
  onRowSave = () => {},
  controls,
  editable,
  onRowDblClick = () => {},
  className,
}: IDataGridArgs) {
  const [iRows, setIRows] = useState({})
  const [perPageCount, setPerPageCount] = useState(5)
  const [page, setPage] = useState(0)
  const [rowsList, setRows] = useState<any[]>([])

  useEffect(() => {
    setRows(rows)
  }, [])

  useEffect(() => {
    if (onChange !== undefined) {
      onChange()
    }
  }, [iRows])


  const updateContent = async () => {
    const isUndefinedRows = !rows
    const isUndefinedColumns = !columns

    if (isUndefinedRows || isUndefinedColumns) {
      return
    }

    // const internalRows: TInternalRows = makeInternalRows(takeJustSelectedRows(rows, perPageCount, page), columns)
    const nextRow = await nextRows(page, perPageCount)
    // console.log('nextRow: ', nextRow)
    if (!nextRow) {
      return
    }
    setRows(nextRow)
    const internalRows: TInternalRows = makeInternalRows(nextRow, columns)
    setIRows(internalRows)
  }

  useEffect(() => {
    updateContent()
  }, [rows, columns, page, perPageCount])

  const rightArrowClick = useCallback(
    () => {
      // setPage((page + 1) * perPageCount >= rows.length ? page : page + 1)
      setPage((page + 1) * perPageCount >= (count || rows.length) ? page : page + 1)
    },
    [page, perPageCount, rows]
  )
  const leftArrowClick = useCallback(
    () => {
      setPage(page > 0 ? page - 1 : 0)
    },
    [page]
  )
  const changePerPageCount = useCallback(
    (count: number) => { setPerPageCount(count) },
    []
  )
  const onRowsSelectionChange = useCallback(
    (selection: string[]) => {
      if (onSelectionChange) {
        onSelectionChange(selection)
      }
    },
    []
  )

  const onEditRow = (editableObject: any) => {
    if (!editableObject) {
      return
    }
    if (onRowChange) {
      onRowChange({ ...editableObject, keyId: editableObject.keyId + page * perPageCount })
    }
  }

  return <TableContainer component={Paper} className={className} sx={{
    borderRadius: 0,
  }}>
        <Table>
            <TableHeader columns={columns}></TableHeader>
            <TableRows
                rowsList={rowsList}
                rows={iRows}
                cols={columns}
                editable={editable}
                onSelectionChange={onRowsSelectionChange}
                onEditRow={onEditRow}
                onDeleteRow={onRowDelete}
                onRowSave={(key: number) => { onRowSave ? onRowSave(key + page * perPageCount) : undefined }}
                onRowDblClick={onRowDblClick}
            ></TableRows>
        </Table>
        {controls && <div
            className={'DataGridControlBar'}
            style={tableControlPanelStyle}
        >
            <div></div>
            <PerPageCount variants={[5, 10, 20, 30, 50, 100, 150, 200, 250]} onChange={changePerPageCount} />
            <PerPageSelector
                startIndex={page * perPageCount}
                endIndex={(page + 1) * perPageCount}
                wholeLength={count || rows?.length || 0}
            />
            <TableArrows onLeftClick={leftArrowClick} onRightClick={rightArrowClick}/>
        </div>}
    </TableContainer>
}
