import { createSlice } from '@reduxjs/toolkit'

export const fileStoresSlice = createSlice({
  name: 'fileStores',
  initialState: {
    fileStores: [],
    selectedFileStores: []
  },
  reducers: {
    setFileStores: (state, action) =>
      ({
        ...state,
        fileStores: action.payload
      }),
    setSelectedFileStores: (state, action) => ({
      ...state,
      selectedFileStores: action.payload
    })
  }
})

export const {
  setFileStores,
  setSelectedFileStores
} = fileStoresSlice.actions
export default fileStoresSlice.reducer
