// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_FileStoreCreationForm__ZXhdQ{
    display: grid;
    grid-template-columns: 12fr;
}

.styles_InputFields__hgq53 {
    margin-top: 30px!important;
    margin-right: 30px!important;
    margin-left: 30px!important;
}
.styles_CountryCityRow__RLYy6{
    display: flex;
    justify-content: start;
}
.styles_CountryCityRow__RLYy6 > * {
    width: 100px;
}
.styles_WeightHeightRow__IdwZu{
    display: flex;
    justify-content: space-between;
}
.styles_WeightHeightRow__IdwZu > * {
    width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/file-store-creation-form/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,YAAY;AAChB","sourcesContent":[".FileStoreCreationForm{\n    display: grid;\n    grid-template-columns: 12fr;\n}\n\n.InputFields {\n    margin-top: 30px!important;\n    margin-right: 30px!important;\n    margin-left: 30px!important;\n}\n.CountryCityRow{\n    display: flex;\n    justify-content: start;\n}\n.CountryCityRow > * {\n    width: 100px;\n}\n.WeightHeightRow{\n    display: flex;\n    justify-content: space-between;\n}\n.WeightHeightRow > * {\n    width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FileStoreCreationForm": `styles_FileStoreCreationForm__ZXhdQ`,
	"InputFields": `styles_InputFields__hgq53`,
	"CountryCityRow": `styles_CountryCityRow__RLYy6`,
	"WeightHeightRow": `styles_WeightHeightRow__IdwZu`
};
export default ___CSS_LOADER_EXPORT___;
