export const emptyScore = {
  value: 0,
  additional: ''
}

export const emptySet = {
  data: [[emptyScore, emptyScore]]
}

export const makeEmptyCompetition = () => ({ ...emptySet })
