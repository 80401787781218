// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_EditableInput__3zBAy{
    /*max-width: 100%;*/
    border: 0;
    padding: 10px;
    border-radius: 0
}

.styles_NonBorderedOnSelected__hdcXw:focus {
    border: none;
    outline: none;
}

.styles_SavingIcon__PYZC4{
    position: absolute;
    right: 30px;
    bottom: 30px;
}
.styles_PreviewImage__l8F9O{
    max-height: 150px;
    max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/data-grid/components/EditableRow/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".EditableInput{\n    /*max-width: 100%;*/\n    border: 0;\n    padding: 10px;\n    border-radius: 0\n}\n\n.NonBorderedOnSelected:focus {\n    border: none;\n    outline: none;\n}\n\n.SavingIcon{\n    position: absolute;\n    right: 30px;\n    bottom: 30px;\n}\n.PreviewImage{\n    max-height: 150px;\n    max-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditableInput": `styles_EditableInput__3zBAy`,
	"NonBorderedOnSelected": `styles_NonBorderedOnSelected__hdcXw`,
	"SavingIcon": `styles_SavingIcon__PYZC4`,
	"PreviewImage": `styles_PreviewImage__l8F9O`
};
export default ___CSS_LOADER_EXPORT___;
