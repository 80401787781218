import HomeIcon from '../../icons/home.svg'
import PeoplesIcon from '../../icons/peoples.svg'
import TournamentsIcon from '../../icons/tournaments.svg'
import SettingsIcon from '../../icons/settings.svg'
import { useSelector } from 'react-redux'
import { type RootState } from '../../app/store'

const AdminMenuObject = () => {
  const loginOperator = useSelector((state: RootState) => state.loginSlice)
  if (loginOperator.role === 'operator') {
    return [{
      name: 'Турниры',
      link: '/tournaments',
      icon: TournamentsIcon
    }]
  }

  return [
    // {
    //   name: 'На главную',
    //   link: '/',
    //   icon: HomeIcon
    // },
    {
      name: 'Операторы',
      link: '/operatores',
      icon: PeoplesIcon
    },
    {
      name: 'Игроки',
      link: '/players',
      icon: PeoplesIcon
    },
    {
      name: 'Турниры',
      link: '/tournaments',
      icon: TournamentsIcon
    },
    {
      name: 'Новости',
      link: '/news',
      icon: SettingsIcon
    },
    {
      name: 'Документы',
      link: '/documents',
      icon: SettingsIcon
    },
    {
      name: 'Настройки',
      link: '/settings',
      icon: SettingsIcon, 
    }
  ]
}

export default AdminMenuObject
