import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { deepCopy } from '../../../shared/procedures'
import { emptySet, makeEmptyCompetition } from '../../../shared/objects'
import { TCompetition } from '../../../widgets/MatchDetailsManager/SetDetailManager'
import { TMatch } from '../../../pages/TournamentsPage/types'
import { TPlayer } from '../../../pages/PlayersPage/types'

import { 
  FINAL_ID,
  HEMIFINAL_ID,
  FOR_SEVENTH_PLACE,
  FOR_FIFTH_PLACE,
} from 'shared/constants' 
import { RootState } from '..'

export interface TMatchSetsAction {
  tourId: number
  cardId: number
  matchId: number
}

function Match(){
  return {
    team1Names: [],
    team2Names: [],
    team1Addition: [''],
    team2Addition: [''],
    hash: null,
    operatorId: null,
    changeStartMatch: null,
    status: 4,
  }
}

function notIsElliminationMatch(){
  return {
    team1Names: [],
    team2Names: [],
    team1Score: [],
    team2Score: [],
    team1Addition: [''],
    team2Addition: [''],
    statistics: {
      player1: [],
      player2: [],
      setStat: []
    },
    matchbeats: [],
    hash: null,
    operatorId: null,
    changeStartMatch: null,
    status: 4,
  }
}

const initialState = {
  name: null,
  date: null,
  manualGridEdition: false,
  gridFirstSize: 4,
  gridLastSize: 2,
  type: 'single',
  gamers: 'men',
  coverage: 'grass',
  live: 'live',
  wonType: '2',
  players: [],
  tours: [],
  internalPlayers: [],
  activePlayers: [],
  matchBeatsArray: [],
  winners: [],
  canSave: false,
  isEllimination: false,
  upperGrid: [[Match(), Match(), Match(), Match()], [Match(), Match()], [Match()]],
  bottomGrid: [[Match(), Match()], [Match(), Match()], [Match()]],
  forSeventPlace: Match(),
  forFifthPlace: Match(),
  hemiFinal: Match(),
  final: Match(),
  selectableParameters: {
    tourId: undefined,
    cardId: undefined,
    teamId: undefined,
  }
}

export const takeSelectedCard = (state: any, selectionParams: any) => {
  const {
    tourId,
    cardId,
    isTopGrid,
    isBottomGrid,
  } = selectionParams

  const { isEllimination } = state

  if(!isEllimination){

    if(cardId === FOR_SEVENTH_PLACE){
      return state.forSeventPlace
    }

    if(cardId === FOR_FIFTH_PLACE){
      return state.forFifthPlace
    }

    if(cardId === HEMIFINAL_ID){
      return state.hemiFinal
    }

    if(cardId === FINAL_ID){
      return state.final
    }

    if(isTopGrid){
      return state.upperGrid[tourId][cardId]
    }

    return state.bottomGrid[tourId][cardId]
  }

  return state.tours[tourId][cardId]
}

export const updateSelectedCard = (state: any, card: any, selectionParams: any, updateHash?: any) => {
  const {
    tourId,
    cardId,
    isTopGrid,
    isBottomGrid,
  } = selectionParams

  const { isEllimination } = state

  const updateMatch = () => {
    if(!updateHash) {
      return card
    }

    return {
      ...notIsElliminationMatch(),
      _id: card._id,
      hash: card.hash,
      operatorId: card.operatorId,
      team1Names: card.team1Names,
      team2Names: card.team2Names,
      datetime: card.datetime,
      changeStartMatch: card.changeStartMatch,
      team1Addition: [card.team1Addition[0]],
      team2Addition: [card.team2Addition[0]],
    }
  }

  if(!isEllimination){

    if(cardId === FOR_SEVENTH_PLACE){
      return {
        ...state,
        forSeventPlace: updateMatch(),
      }
    }

    if(cardId === FOR_FIFTH_PLACE){
      return {
        ...state,
        forFifthPlace: updateMatch(),
      }
    }

    if(cardId === HEMIFINAL_ID){
      return {
        ...state,
        hemiFinal: updateMatch(),
      }
    }

    if(cardId === FINAL_ID){
      return {
        ...state,
        final: updateMatch(),
      }
    }

    if(isTopGrid){
      const newUpperGrid = deepCopy(state.upperGrid)
      newUpperGrid[tourId][cardId] = updateMatch()
      return {
        ...state,
        upperGrid: newUpperGrid  
      }
    }

    const newBottomGrid = deepCopy(state.bottomGrid)
    newBottomGrid[tourId][cardId] = updateMatch()
    return {
      ...state,
      bottomGrid: newBottomGrid  
    }
  }

  const newTours = deepCopy(state.tours)
  newTours[tourId][cardId] = updateMatch()
  return {
    ...state,
    tours: newTours
  }
}


export const clearSelectedCard = (state: any, selectionParams: any) => {
  const {
    tourId,
    cardId,
    isTopGrid,
    isBottomGrid,
  } = selectionParams

  const { isEllimination } = state

  if(!isEllimination){

    if(cardId === FOR_SEVENTH_PLACE){
      return {
        ...state,
        forSeventPlace: {
          _id: state.forSeventPlace._id,
          ...notIsElliminationMatch()
        },
      }
    }

    if(cardId === FOR_FIFTH_PLACE){
      return {
        ...state,
        forFifthPlace: {
          _id: state.forFifthPlace._id,
          ...notIsElliminationMatch()
        },
      }
    }

    if(cardId === HEMIFINAL_ID){
      return {
        ...state,
        hemiFinal: {
          _id: state.hemiFinal._id,
          ...notIsElliminationMatch()
        },
      }
    }

    if(cardId === FINAL_ID){
      return {
        ...state,
        final: {
          _id: state.final._id,
          ...notIsElliminationMatch()
        },
      }
    }

    if(isTopGrid){
      const newUpperGrid = deepCopy(state.upperGrid)
      newUpperGrid[tourId][cardId] = {
        _id: state.upperGrid[tourId][cardId]._id,
        ...notIsElliminationMatch()
      }
      return {
        ...state,
        upperGrid: newUpperGrid  
      }
    }

    const newBottomGrid = deepCopy(state.bottomGrid)
    newBottomGrid[tourId][cardId] = {
      _id: state.bottomGrid[tourId][cardId]._id,
      ...notIsElliminationMatch()
    }
    return {
      ...state,
      bottomGrid: newBottomGrid  
    }
  }

  const newTours = deepCopy(state.tours)
  newTours[tourId][cardId] = {
    ...notIsElliminationMatch(),
    _id: newTours[tourId][cardId]._id
  }
  return {
    ...state,
    tours: newTours
  }
}

export const editableTournamentSlice = createSlice({
  name: 'editableTournament',
  initialState,
  reducers: {
    setCanSave: (state, {payload}) => {
      return {
        ...state,
        canSave: payload,
      }
    },
    setIsEllimination: (state, {payload}) => {
      return {
        ...state,
        isEllimination: payload,
        selectableParameters: initialState.selectableParameters,
      }
    },
    removePlayerFromMatch: (state, {payload: {playerId, ...selectableParams}}) => {
      const card = deepCopy(takeSelectedCard(state, selectableParams))

      card.team1Names = card.team1Names.filter((p: TPlayer) => (p.id) != playerId)
      card.team2Names = card.team2Names.filter((p: TPlayer) => (p.id) != playerId)

      return updateSelectedCard(state, card, selectableParams)
    },
    setMatchDatetime: (state, {payload: {datetime, ...selectableParams}}) => {
      // if(state.selectableParameters.tourId === undefined){
      //   return state
      // }
      console.log('datetime: ', datetime)
      const card = deepCopy(takeSelectedCard(state, selectableParams))
      card.datetime = `${datetime}`
      return updateSelectedCard(state, card, selectableParams)
    },
    setAdittionRet: (state, {payload: {teamId, ...selectableParams}}) => {
      const card = deepCopy(takeSelectedCard(state, selectableParams))
      if(teamId === 0) {
        card.team1Addition.includes("ret") ? card.team1Addition.splice(card.team1Addition.indexOf("ret"), 1) : card.team1Addition.push("ret")
      }
      if(teamId === 1) {
        card.team2Addition.includes("ret") ? card.team2Addition.splice(card.team2Addition.indexOf("ret"), 1) : card.team2Addition.push("ret")
      }
      return updateSelectedCard(state, card, selectableParams)
    },
    setSelectionParameters: (state, {payload: {
      tourId, cardId, teamId, isTopGrid, isBottomGrid,
    }}) => {
      return {
        ...state,
        selectableParameters: {
          tourId,
          cardId,
          teamId,
          isTopGrid,
          isBottomGrid,
        }
      }
    },
    setTourId: (state, {payload}) => {
      return {
        ...state,
        selectableParameters: {
          ...state.selectableParameters,
          tourId: payload,
        }
      }
    },
    setActivePlayers: (state, {payload}) => {
      return {
        ...state,
        activePlayers: payload,
      }
    },
    setInternalPlayers: (state, {payload}) => {
      return {
        ...state,
        internalPlayers: payload.map((player => ({...player, avatar: undefined}))),
      }
    },
    setTours: (state, {payload}) => {
      return {
        ...state,
        tours: payload,
      }
    },
    manualChangeGridSize: (state, {payload}) => {
      if(!state.manualGridEdition){
        return state
      }
      return {
        ...state,
        gridFirstSize: payload,
      }
    },
    manualChangeGridLastSize: (state, {payload}) => {
      if(!state.manualGridEdition){
        return state
      }
      return {
        ...state,
        gridLastSize: payload,
      }
    },
    switchManualGridEdition: (state) => {
      return {
        ...state,
        manualGridEdition: !state.manualGridEdition,
      }
    },
    clearEditableTournament: () => {
      return initialState
    },
    setCard: (state, { payload: { tourId, cardId, data } }) => {
      const newTours = deepCopy(state.tours)
      newTours[tourId][cardId] = data

      return {
        ...state,
        tours: newTours
      }
    },
    setEditableTournamentFields: (state, action) => {
       return ({
        ...state,
        ...action.payload,
        })
    },
    appendEmptyScores: (state, action: PayloadAction<TMatchSetsAction>) => {
      const newTours = deepCopy(state.tours)

      const { cardId, tourId, matchId } = action.payload

      newTours[tourId][cardId]
        .sets[matchId]
        .scores.push(makeEmptyCompetition())

      return {
        ...state,
        tours: newTours
      }
    },
    popScores: (state, action: PayloadAction<TMatchSetsAction>) => {
      const newTours = deepCopy(state.tours)

      const {
        cardId,
        tourId,
        matchId
      } = action.payload

      newTours[tourId][cardId].sets[matchId].scores.pop()

      return {
        ...state,
        tours: newTours
      }
    },
    pushCompetition: (state, action) => {
      const newTours = deepCopy(state.tours)

      const {
        cardId,
        tourId,
        matchId,
        setId,
        competition
      } = action.payload

      newTours[tourId][cardId]
        .sets[matchId]
        .scores[setId]
        .data.push(...competition.data)

      return {
        ...state,
        tours: newTours
      }
    },
    changeSingleScore: (state, action) => {
      const newTours = deepCopy(state.tours)

      const {
        cardId,
        tourId,
        matchId,
        setId,
        i,
        value,
        position
      } = action.payload

      newTours[tourId][cardId]
        .sets[matchId]
        .scores[setId]
        .data[i][position].value = value

      return {
        ...state,
        tours: newTours
      }
    },
    changeSingleAddition: (state, action) => {
      const newTours = deepCopy(state.tours)

      const {
        cardId,
        tourId,
        matchId,
        setId,
        i,
        value,
        position
      } = action.payload

      newTours[tourId][cardId]
        .sets[matchId]
        .scores[setId]
        .data[i][position].additional = value

      return {
        ...state,
        tours: newTours
      }
    },
    pushToMatchBeatsArray: (state: any, action: any) => {
      const { tourId, cardId, matchId, matchbeats } = action.payload
      const newTours = deepCopy(state.tours)
      if (!newTours[tourId][cardId]?.matchbeats) {
        newTours[tourId][cardId].matchbeats = []
      }
      newTours[tourId][cardId].matchbeats[matchId] = matchbeats
      return {
        ...state,
        tours: newTours

      }
    },
    pushToMatchBeats: (state: any, action: any) => {
      const { tourId, cardId, matchbeats } = action.payload
      const newTours = deepCopy(state.tours)
      if (!newTours[tourId][cardId]?.matchbeats) {
        newTours[tourId][cardId].matchbeats = []
      }
      newTours[tourId][cardId] = matchbeats
      return {
        ...state,
        tours: newTours

      }
    },
    pushToIdOperator: (state: any, action: any) => {
      const { tourId, cardId, operatorId } = action.payload
      const newTours = deepCopy(state.tours)
      newTours[tourId][cardId].operatorId = Number(operatorId)
      return {
        ...state,
        tours: newTours

      }
    },
    changeOnlineStatus: (state: any, action: any) => {
      const selectionParams = action.payload

      const selectedCard = deepCopy(takeSelectedCard(state, selectionParams))
      selectedCard.online = !selectedCard.online
      return updateSelectedCard(state, selectedCard, selectionParams)
    },

    clearMatch: (state: any, {payload: selectableParams}) => {
        return clearSelectedCard(state, selectableParams)
    },

    updateTeam: (state: any, action: any) => {
      console.log('ACTION: ', action)
      const {players, teamId } = action.payload
      const selectableParams = state.selectableParameters
      let internalPlayers = state.internalPlayers;
      let activePlayers = state.activePlayers;
      const card = deepCopy(takeSelectedCard(state, selectableParams))

      const filterByPlayerId = (ps: TPlayer, team: TPlayer[]) => {
        for(let i = 0; i < team.length; i++){
          if(ps.id === team[i].id){
            return false
          }
          return true
        }
      } 

      if(teamId === 0){

        // if(card.team1Names.length > 0){
        //   internalPlayers = [...state.internalPlayers, ...card.team1Names]
        //   activePlayers = state.activePlayers.filter((p: TPlayer) => filterByPlayerId(p, card.team1Names))
        // }

        // activePlayers = activePlayers ? [...activePlayers, ...players] : players
        // internalPlayers = internalPlayers.filter((player: TPlayer) => {
        //   for( let i = 0; i < players.length; i++ ){
        //     if(players[i].id === player.id){
        //       return false
        //     }
        //     return true
        //   }
        // })

        card.team1Names = players
      }else{

        // if(card.team2Names.length > 0){
        //   internalPlayers = [...state.internalPlayers, ...card.team2Names]
        //   activePlayers = state.activePlayers.filter((p: TPlayer) => filterByPlayerId(p, card.team2Names))
        // }

        // activePlayers = activePlayers ? [...activePlayers, ...players] : players
        // internalPlayers = internalPlayers.filter((player: TPlayer) => {
        //   for( let i = 0; i < players.length; i++ ){
        //     if(players[i].id === player.id){
        //       return false
        //     }
        //     return true
        //   }
        // })
        

        card.team2Names = players
      }

      return {
        ...updateSelectedCard(state, card, selectableParams),
        internalPlayers: internalPlayers,
        activePlayers: (activePlayers ? activePlayers : state.activePlayers),
      }
    }
  },
})

export const {
  setCanSave,
  pushToIdOperator,
  pushToMatchBeatsArray,
  pushToMatchBeats,
  pushCompetition,
  setEditableTournamentFields,
  appendEmptyScores,
  popScores,
  changeSingleScore,
  changeSingleAddition,
  setCard,
  clearEditableTournament,
  changeOnlineStatus,
  switchManualGridEdition,
  manualChangeGridSize,
  manualChangeGridLastSize,
  setTours,
  setSelectionParameters,
  setInternalPlayers,
  setActivePlayers,
  setMatchDatetime,
  setAdittionRet,
  removePlayerFromMatch,
  setIsEllimination,
  updateTeam,
  setTourId,
  clearMatch,
} = editableTournamentSlice.actions
export default editableTournamentSlice.reducer
