import styles from './styles.module.css'
import { Game } from './Game/Game'
import { Aces, BreakPoint, DoubleFaults, HoldSign } from './svg'
import { createRef, useCallback, useEffect, useState } from 'react'
import { type IMatchBeatsWrapperArgs, type TMatch } from './types'

enum EWidgetRegimes {
  empty,
  activeMatch,
  refresh
}

const regimeClueMessage: any = {
  [EWidgetRegimes.empty]: 'Выберите подающего',
  [EWidgetRegimes.activeMatch]: 'Выберите подебиделя матча',
  [EWidgetRegimes.refresh]: 'Выберите подающего'
}

const GAME = 100
const AD = 200
const BREAKPOINT = 300
const ACE = 1
const DOUBLE_FAULTS = 2

type TScores = 15 | 30 | 40 | 100 | 200 | 300 | 1 | 2 | 41
type TWidgetMatch = [TScores, TScores, boolean, boolean, TScores, TScores]

interface TWidgetSet {
  holds: 0 | 1
  scores: TWidgetMatch[]
}

export function MatchBeatsWidget ({ beats }: IMatchBeatsWrapperArgs) {

  const [thisMobile, setThisMobile] = useState(false)
  const [isActiveGrub, setIsActiveGrub] = useState(false)
  const [offset, setOffset] = useState(0)
  const [clickPosition, setClickPosition] = useState(-1)

  const activateGrab = useCallback((event: any) => {
    setIsActiveGrub(true)
    setClickPosition(event.clientX)
  }, [])

  const activateGrabMobile = useCallback((event: any) => {
    setThisMobile(true)
    setIsActiveGrub(true)
    setClickPosition(event.changedTouches[0].clientX)
  }, [])

  const deactivateGrab = useCallback(() => {
    setThisMobile(false)
    setIsActiveGrub(false)
    setClickPosition(-1)
  }, [])

  const widthWindow = createRef<HTMLDivElement>()
  const widthBlock = createRef<HTMLDivElement>()

  const doGrab = useCallback((event: any) => {
    if (!isActiveGrub || !widthWindow.current || !widthBlock.current) {
      return
    }
    let newOffset = offset - (clickPosition - (thisMobile ? event.changedTouches[0].clientX : event.clientX))
    if (newOffset > 10) {
      newOffset = 10
    }

    if (newOffset < widthWindow.current.offsetWidth - (widthBlock.current.offsetWidth + 300)) {
      newOffset = widthWindow.current.offsetWidth - (widthBlock.current.offsetWidth + 300)
    }
    setOffset(newOffset)
    setClickPosition(thisMobile ? event.changedTouches[0].clientX : event.clientX)
  }, [clickPosition])

  const translateToMaxValue = () => {
    if (!widthWindow.current || !widthBlock.current) {
      return
    }
    const maxScrollValue = widthWindow.current.scrollWidth - widthBlock.current.clientWidth
    setOffset(maxScrollValue - 250)

    // debugger;
  }

  useEffect(() => {
    translateToMaxValue()
  }, [beats])

  return <div
        ref={widthWindow}
        className={styles.MatchBeatsWrapper}>

        <div className={styles.PlayersNamesBlock}>
            {
                beats.opponents.map((opponent: any /* todo */, oid: number) => {
                  return <div key={oid} className={styles.OpponentName}>
                        <div className={styles.OpponentNameLeftDivider}/>
                        <div className={styles.OpponentNameText}>{opponent.name}</div>
                    </div>
                })
            }
        </div>
        <div className={styles.BeatsLineWrapper}>
            <div
                onMouseDown={activateGrab}
                onMouseUp={deactivateGrab}
                onMouseMove={doGrab}
                onMouseLeave={deactivateGrab}

                onTouchStart={activateGrabMobile}
                onTouchEnd ={deactivateGrab}
                onTouchMove ={doGrab}
                onTouchCancel ={deactivateGrab}

                className={styles.MatchBeats}
                style={{ transform: `translate(${offset}px, 0)` }} ref={widthBlock}>

                <div className={styles.SetName}>{beats.setName}</div>

                {beats.data.map((game: TMatch) => {
                  return <>
                        <Game game={game}/>

                        {game.results && <div className={styles.GameResults}>
                            {game.results.holds === 0 && <div className={styles.Holds}>{beats.opponents[0].name} HOLDS</div> }
                            {game.results.holds !== 0 && <div className={styles.GameTime}>
                                <img className={styles.TimeImage} src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMzNjM2MzY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCw4QTQsNCwwLDEsMSw4LDQsNCw0LDAsMCwxLDQsOFpNNCwuNUEzLjUsMy41LDAsMSwwLDcuNSw0LDMuNSwzLjUsMCwwLDAsNCwuNVptMCwwIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yNDIsMTA1LjkxNWEuMjQ4LjI0OCwwLDAsMS0uMTc3LS4wNzNsLTEuNzUtMS43NWEuMjUuMjUsMCwwLDEtLjA3My0uMTc3di0yLjMzM2EuMjUuMjUsMCwwLDEsLjUsMHYyLjIzbDEuNjc3LDEuNjc3YS4yNS4yNSwwLDAsMS0uMTc3LjQyN1ptMCwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2LjI1IC05OS43NDkpIi8+PC9zdmc+'} />
                                {
                                    game.results.time}</div> }
                            <div className={styles.GameScores}>
                                <div className={styles.GameScoreBar}>

                                    {game.results.holds === 0 ? <HoldSign /> : <div className={styles.HoldsScore}></div>}
                                    <div className={styles.ScoreWrapper}>
                                        <div className={styles.ResultScore}>{`${game.results.setScore[0]}`}</div>
                                        {game.results.tie && game.results.tie[0] > 0 && <div className={styles.ResultTieScore}>{`${(game.results.tie[0]) || ''}`}</div>}
                                    </div>

                                    {game.results.holds === 1 ? <HoldSign /> : <div className={styles.HoldsScore}></div>}
                                    <div className={styles.ScoreWrapper}>
                                        <div className={styles.ResultScore}>{`${game.results.setScore[1]}`}</div>
                                        {game.results.tie && game.results.tie[1] > 0 && <div className={styles.ResultTieScore}>{`${(game.results.tie[1]) || ''}`}</div>}
                                    </div>

                                </div>
                            </div>
                            {game.results.holds === 1 && <div className={styles.Holds}>{beats.opponents[1].name} HOLDS</div> }
                            {game.results.holds !== 1 && <div className={styles.GameTime}>
                                <img className={styles.TimeImage} src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMzNjM2MzY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCw4QTQsNCwwLDEsMSw4LDQsNCw0LDAsMCwxLDQsOFpNNCwuNUEzLjUsMy41LDAsMSwwLDcuNSw0LDMuNSwzLjUsMCwwLDAsNCwuNVptMCwwIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yNDIsMTA1LjkxNWEuMjQ4LjI0OCwwLDAsMS0uMTc3LS4wNzNsLTEuNzUtMS43NWEuMjUuMjUsMCwwLDEtLjA3My0uMTc3di0yLjMzM2EuMjUuMjUsMCwwLDEsLjUsMHYyLjIzbDEuNjc3LDEuNjc3YS4yNS4yNSwwLDAsMS0uMTc3LjQyN1ptMCwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2LjI1IC05OS43NDkpIi8+PC9zdmc+'} />
                                {game.results.time}</div> }
                        </div>}
                    </>
                })}
            </div>
        </div>
        <div className={styles.UserSchema}>
            <BreakPoint/>
            <DoubleFaults/>
            <Aces/>
        </div>
    </div>
}
