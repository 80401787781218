import { TOperatoresCountResponse, TOperatoresListResponse } from 'pages/OperatoresPage/types'
import { type TPlayer, type TPlayerCreationResponse, type TPlayerListResponse } from '../../../pages/PlayersPage/types'

const OPERATOR_URL = `${process.env.REACT_APP_SERVER_BACKEND_URL}/admin`

export type TOperator = {
  login: string
  role: string
  id: string
}

export function OperatorManipulationService () {

  const getCountOperatores = async () => {
    const fetchRes = await fetch(`${OPERATOR_URL}/count`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для получения количества операторов',
        count: 0
      }
    }
    const response: TOperatoresCountResponse = await fetchRes.json()

    if (fetchRes.status === 406) {
      return {
        errorMessage: response.message || null,
        count: 0
      }
    }

    return {
      errorMessage: null,
      count: response.count,
    }
  }

  const getOperatores = async (page: number, count: number) => {
    const fetchRes = await fetch(`${OPERATOR_URL}?page=${page}&count=${count}`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для получения списка операторов',
        operators: null
      }
    }
    const response: TOperatoresListResponse = await fetchRes.json()

    if (fetchRes.status === 406) {
      return {
        errorMessage: null,
        operators: null
      }
    }

    return {
      errorMessage: null,
      operators: response,
    }
  }


  const takeOperatorList = async () => {
    const fetchRes = await fetch(OPERATOR_URL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (fetchRes.status === 403) {
      return {
        errorMessage: 'Недостаточно прав для получения списка операторов',
        operators: null
      }
    }
    const response: TOperatoresListResponse = await fetchRes.json()

    if (fetchRes.status === 406) {
      return {
        errorMessage: null,
        operators: null
      }
    }

    return {
      errorMessage: null,
      operators: response,
    }
  }

  return {
    getCountOperatores,
    getOperatores,
    takeOperatorList,
  }
}
