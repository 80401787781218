import { createSlice } from '@reduxjs/toolkit'

export const allNewsSlice = createSlice({
  name: 'allNews',
  initialState: {
    allNews: [],
    selectedAllNews: [],
    editableNews: {
      headers1: null,
      headers2: null,
      text: null,
      headers1EN: null,
      headers2EN: null,
      textEN: null,
      banner: null,
      date: null
    },
    count: 0
  },
  reducers: {
    setAllNews: (state, action) =>
      ({
        ...state,
        allNews: action.payload
      }),
    setCountNews: (state: any, action: {payload: number}) => {
      return {
        ...state,
        count: action.payload
      }
    },
    setSelectedAllNews: (state, action) => 
      ({
      ...state,
      selectedAllNews: action.payload
      }),
    setEditableNews: (state, {payload: editableNews}) => {
      return {
        ...state,
        editableNews: {
          ...state.editableNews,
          ...editableNews
        }
      }
    },
    clearEditableNews: (state) =>
    ({
      ...state,
      editableNews: {
        headers1: null,
        headers2: null,
        text: null,
        headers1EN: null,
        headers2EN: null,
        textEN: null,
        banner: null,
        date: null
      }
    })
  }
})

export const {
  setCountNews,
  setAllNews,
  setSelectedAllNews,
  setEditableNews,
  clearEditableNews
} = allNewsSlice.actions
export default allNewsSlice.reducer
