import styles from './styles.module.css'
import { TextInputField } from '../../../entities/TextInputField'
import { type SyntheticEvent, useCallback, useState, useEffect } from 'react'
import { Alert } from '@mui/material'
import { SelectionMenu } from '../../../entities/SelectionMenu'
import { connect, useDispatch } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../../pages/TournamentsPage/maps'
import { type TSelectionVariant } from '../../../entities/SelectionMenu/types'
import { setCanSave } from 'app/store/slices/editableTournament'
import { getGMTDate } from 'shared/procedures'

const dict = {
  name: 'Название турнира',
  startDate: 'Дата начала турнира',
  completeDate: 'Дата завершения турнира',
  type: 'Тип турнира',
  gamers: 'Тип игроков',
  country: 'Двубуквенное обозначение страны',
  countryName: 'Название страны',
  coverage: 'Покрытие корта',
  live: 'Лайв',
  wonType: 'До скольки побед',
  isEllimination: 'Отборочный'
}

const tournamentSelectableRegimeSelections = [
  {
    label: 'Отборочный',
    value: 'selectable',
  },
  {
    label: 'Основной',
    value: 'main',
  }
]

const tournamentTypeSelections = [
  {
    label: 'Single',
    value: 'single'
  },
  {
    label: 'Double',
    value: 'double'
  }
]

const gamersTypeSelections = [
  {
    label: 'Men',
    value: 'men'
  },
  {
    label: 'Women',
    value: 'women'
  },
  {
    label: 'Juniors',
    value: 'juniors'
  }
]

const coverageSelectionVariants = [
  {
    label: 'Трава',
    value: 'grass'
  },
  {
    label: 'Грунт',
    value: 'ground'
  },
  {
    label: 'Хард',
    value: 'hard'
  }
]

const liveSelectionVariants = [
  {
    label: 'LIVE',
    value: 'live'
  },
  {
    label: 'Ожидается',
    value: 'awaiting'
  },
  {
    label: 'Завершен',
    value: 'complete'
  }
]

const wonTypeSelectionVariants = [
  {
    label: 'До 2-х побед',
    value: '2'
  },
  {
    label: 'До 3-х побед',
    value: '3'
  }
]

export const TournamentsCreationForm = connect(mapStateToProps, mapDispatchToProps)(function ({
  name,
  startDate,
  completeDate,
  type,
  gamers,
  live,
  coverage,
  country,
  countryName,
  isEllimination,
  setTournamentAttributes,
  setIsEllimination,
}: any) {
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const [dataValidationText, setDataValidationText] = useState('')
  const [tournamentNameValidationText, setTournamentNameValidationText] = useState('')
  const [isoCountryCodeMessage, setIsoCountryCodeMessage] = useState('')
  const [countryMessage, setCountryMessage] = useState('')
  const [checkingsIsActive, setCheckingsIsActive] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const valuesList: string[] = [
      dataValidationText, 
      tournamentNameValidationText, 
      isoCountryCodeMessage, 
      countryMessage
    ]

    const canSave = !valuesList.some(el => el !== '')

    dispatch(setCanSave(canSave))
  }, [
    dataValidationText, 
    tournamentNameValidationText, 
    isoCountryCodeMessage, 
    countryMessage
  ])

  const setTournamentName = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setTournamentAttributes({ name: e.currentTarget.value }),
    [setTournamentAttributes])

  const setStartTournamentDate = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTournamentAttributes({ startDate: e.currentTarget.value })
        setCheckingsIsActive(true)
    },
    [setTournamentAttributes])

  const setCompleteTournamentDate = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTournamentAttributes({ completeDate: e.currentTarget.value })
      setCheckingsIsActive(true)
    },
    [setTournamentAttributes])

  const onTypeSelected = useCallback((v: TSelectionVariant) =>
    setTournamentAttributes({ type: v.value }),
  [setTournamentAttributes])

  const onGamersSelected = useCallback((v: TSelectionVariant) =>
    setTournamentAttributes({ gamers: v.value }),
  [setTournamentAttributes])

  const onCoverageSelected = useCallback((v: TSelectionVariant) =>
    setTournamentAttributes({ coverage: v.value }),
  [setTournamentAttributes])

  const onLiveSelected = useCallback((v: TSelectionVariant) =>
    setTournamentAttributes({ live: v.value }),
  [setTournamentAttributes])

  const onWonTypeSelected = useCallback((v: TSelectionVariant) =>
    setTournamentAttributes({ wonType: v.value }),
  [setTournamentAttributes])

  const onCountryChange = useCallback((v: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setTournamentAttributes({ country: v.currentTarget.value }),
  [setTournamentAttributes])

  const onCountryNameChange = useCallback((v: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setTournamentAttributes({ countryName: v.currentTarget.value }),
  [setTournamentAttributes])

  const onSelectableRegimeChange = useCallback((v: TSelectionVariant) => {
    setIsEllimination(v.value === 'selectable')
  },
  [setTournamentAttributes])

  const dataValidation = (value: string) => {

    if(!checkingsIsActive){
      return true
    }

    if( value === '' ){
      setDataValidationText('Дата турнира должна быть задана')
      return false
    }

    const currentDate = new Date()
    const targetDate = new Date(value)
    const delta = targetDate.getTime() - currentDate.getTime()

    if( delta < 0 ){
      setDataValidationText('Целевая дата не может быть меньше текущей')
      return false
    }
    setDataValidationText('')
    return true
  }

  const tournamentNameValidationRule = (value: string) => {
    if(value === ''){
      setTournamentNameValidationText('Название турнира должно быть задано')
      return false
    }
    setTournamentNameValidationText('')
    return true
  }

  const isoCountryCodeValidationRule = (value: string) => {

    const reg = /^[A-Z]{2}$/

    if(value === ''){
      setIsoCountryCodeMessage('Требуется двубуквенное обозначение страны большими буквами')
      return false
    }

    if(!reg.test(value)){
      setCountryMessage('Только двубуквенное обозначение страны большими латинскими буквами')
      return false
    }

    setIsoCountryCodeMessage('')
    return true
  }

  const countryCodeValidationRule = (value: string) => {

    if(!value){
      setCountryMessage('Требуется полное название страны')
      return false
    }

    setCountryMessage('')
    return true
  }



  return <form className={styles.TournamentsCreationForm}>
        <TextInputField
            value={name}
            className={styles.InputFields}
            id={'TournamentName'}
            label={dict.name}
            validation={tournamentNameValidationRule}
            errorText={tournamentNameValidationText}
            onChange={setTournamentName} />
        <div className={styles.DateRow}>
          <TextInputField
              value={startDate}
              className={styles.InputFields}
              id={'TournamentDate'}
              label={dict.startDate}
              type={'date'}
              validation={dataValidation}
              errorText={dataValidationText}
              onChange={setStartTournamentDate} />
          <TextInputField
              value={completeDate}
              className={styles.InputFields}
              id={'TournamentDate'}
              label={dict.completeDate}
              type={'date'}
              validation={dataValidation}
              errorText={dataValidationText}
              onChange={setCompleteTournamentDate} />
        </div>
        <div className={styles.Selectors}>
          <div className={styles.SelectorWrapper}>
              <SelectionMenu 
                selectionVariants={tournamentTypeSelections} 
                label={dict.type} 
                onSelect={onTypeSelected}
                selectedValue={type}
              />
          </div>
          <div className={styles.SelectorWrapper}>
              <SelectionMenu 
                selectionVariants={gamersTypeSelections} 
                label={dict.gamers} 
                selectedValue={gamers}
                onSelect={onGamersSelected}
              />
          </div>
          <div className={styles.SelectorWrapper}>
              <SelectionMenu
                selectionVariants={coverageSelectionVariants}
                label={dict.coverage}
                onSelect={onCoverageSelected}
                selectedValue={coverage}
              />
          </div>
          <div className={styles.SelectorWrapper}>
            <SelectionMenu
              selectionVariants={liveSelectionVariants}
              label={dict.live}
              onSelect={onLiveSelected}
              selectedValue={live}
            />
        </div>
        </div>
        <div className={styles.CountryISOWithWonTypeRow}>
          <TextInputField
              value={country}
              className={styles.InputFields}
              id={'TournamentCountry'}
              label={dict.country}
              validation={isoCountryCodeValidationRule}
              errorText={isoCountryCodeMessage}
              onChange={onCountryChange}
          />
          <div className={styles.SelectorWrapper}>
              <SelectionMenu
                  selectionVariants={wonTypeSelectionVariants}
                  label={dict.wonType}
                  onSelect={onWonTypeSelected}
              />
          </div>
          <div className={styles.SelectorWrapper}>
              <SelectionMenu
                  selectionVariants={tournamentSelectableRegimeSelections}
                  label={dict.isEllimination}
                  onSelect={onSelectableRegimeChange}
                  selectedValue={isEllimination ? tournamentSelectableRegimeSelections[0].value : tournamentSelectableRegimeSelections[1].value}
              />
          </div>
        </div>
        <TextInputField
            value={countryName}
            className={styles.InputFields}
            id={'TournamentCountryName'}
            label={dict.countryName}
            validation={countryCodeValidationRule}
            errorText={countryMessage}
            onChange={onCountryNameChange}
        />
        
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
    </form>
})
