// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_IconButton__JUZCA {
    padding: 10px;
    display: flex;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
}

.styles_IconButton__JUZCA:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.styles_Icon__\\+PVeY {
    width: 15px;
    height: 15px;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}`, "",{"version":3,"sources":["webpack://./src/entities/IconButton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB,EAAE,WAAW,EACf,oBAAoB;IAC3C,iBAAiB,EAAE,oBAAoB;AAC3C","sourcesContent":[".IconButton {\n    padding: 10px;\n    display: flex;\n    border-radius: 30px;\n    justify-content: center;\n    align-items: center;\n}\n\n.IconButton:hover {\n    background: rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n}\n\n.Icon {\n    width: 15px;\n    height: 15px;\n    -webkit-user-select: none; /* Safari */\n    -ms-user-select: none; /* IE 10 and IE 11 */\n    user-select: none; /* Standard syntax */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": `styles_IconButton__JUZCA`,
	"Icon": `styles_Icon__+PVeY`
};
export default ___CSS_LOADER_EXPORT___;
