import SearchIcon from '../../icons/search.svg'
import CreateIcon from '../../icons/create.svg'
import DeleteIcon from '../../icons/remove.svg'
import SaveIcon from '../../icons/save.svg'
import { type IIconMenuArgs } from './types'
import { Alert } from '@mui/material'
import styles from './styles.module.css'
import { IconButton } from '../../entities/IconButton'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'app/store'
import { connect } from 'react-redux'

const mapStateToProps = (state: RootState) => ({
  errorMessage: state.alertSlice.errorMessage,
  infoMessage: state.alertSlice.infoMessage,
})

export const IconMenu = connect(mapStateToProps)(({
  onSearchClick,
  onCreateClick,
  onDeleteClick,
  onSaveClick,
  errorMessage,
  infoMessage,
}: IIconMenuArgs) => {

  const navigate = useNavigate()

  const logout = () => {
    navigate('/')
  }

  return <div className={styles.IconMenu}>
    {onSearchClick && <IconButton icon={SearchIcon} onClick={onSearchClick} />}
    {onCreateClick && <IconButton icon={CreateIcon} onClick={onCreateClick} />}
    {onDeleteClick && <IconButton icon={DeleteIcon} onClick={onDeleteClick} />}
    {onSaveClick && <IconButton icon={SaveIcon} onClick={onSaveClick} />}
    <div className={styles.AlertBar}>
      {errorMessage && <Alert severity={'error'}>{errorMessage}</Alert>}
      {infoMessage  && <Alert severity={'info'}>{infoMessage}</Alert>}
    </div>
    <div className={styles.RightCommonControl}>
      <div className={styles.Help}>
        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#323232" />
          <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#323232" stroke-width="2" />
          <path d="M10.5 8.67709C10.8665 8.26188 11.4027 8 12 8C13.1046 8 14 8.89543 14 10C14 10.9337 13.3601 11.718 12.4949 11.9383C12.2273 12.0064 12 12.2239 12 12.5V12.5V13" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 16H12.01" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div className={styles.Logout} onClick={logout}>
        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 12L13 12" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
})
