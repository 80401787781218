import { type TAuthPackage, type TUser } from './types'

const ROOT_URL = process.env.REACT_APP_SERVER_BACKEND_URL

export const AuthService = () => {
  const auth = async (login: string, password: string) => {
    const authorizationResult = await fetch(`${ROOT_URL}/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        login,
        password
      })
    })

    if (authorizationResult.status === 403) {
      /**
             * Реакция на 403
             */
      return {
        status: 'forbidden'
      }
    }

    const resultDto: TAuthPackage = await authorizationResult.json()

    /**
         * Положительный ответ
         */
    return resultDto
  }

  const createUser = async (user: TUser, token: string) => {
    const authorizationResult = await fetch(`${ROOT_URL}/admin/make`, {
      method: 'POST',
      headers: {
        rootAccessHash: token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })

    if (authorizationResult.status === 403) {
      /**
             * Реакция на 403
             */
      return {
        status: 'forbidden',
        message: 'Корневой ключ доступа не прошел проверку. Ваш аккаунт не имеет прав на создание пользователь админ-панели'
      }
    }

    if (authorizationResult.status === 406) {
      /**
             * Реакция на 406
             */
      return {
        status: 'duplicate',
        message: 'Такой логин уже существует'
      }
    }

    const resultDto: TAuthPackage = await authorizationResult.json()

    /**
         * Положительный ответ
         */
    return resultDto
  }

  return {
    auth,
    createUser
  }
}
