import { createSlice } from '@reduxjs/toolkit'
import { TTournament } from 'pages/TournamentsPage/types'

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState: {
    tournaments: [],
    selectedTournaments: [],
    count: 0
  },
  reducers: {
    setTournaments: (state, action) =>
      ({
        ...state,
        tournaments: action.payload
      }),
    setChankTournaments: (state: any, action: {payload: TTournament[]}) => {
      return {
        ...state,
        tournaments: [...state.tournaments, ...action.payload]
      }
    },
    setCountTournaments: (state: any, action: {payload: number}) => {
      return {
        ...state,
        count: action.payload
      }
    },
    setSelectedTournaments: (state, action) => ({
      ...state,
      selectedTournaments: action.payload
    })
  }
})

export const {
  setTournaments,
  setChankTournaments,
  setCountTournaments,
  setSelectedTournaments
} = tournamentsSlice.actions
export default tournamentsSlice.reducer
