import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { type ISelectionMenuArgs, type TSelectionVariant } from './types'

export function SelectionMenu ({ selectionVariants, onSelect, label, selectedValue }: ISelectionMenuArgs) {
  return <TextField
        id="outlined-select-currency"
        select
        sx={{
          minWidth: '150px',
          '.MuiSelect-select': {
            fontSize: '14px!important'
          }
        }}
        label={label}
        value={selectedValue || selectionVariants[0].value}
        defaultValue={selectedValue || selectionVariants[0].value}
    >
        {selectionVariants.map((option: TSelectionVariant) => (
            <MenuItem key={option.value} value={option.value} onClick={() => { onSelect(option) }}>
                {option.label}
            </MenuItem>
        ))}
    </TextField>
}
