import styles from './styles.module.css'
import { TextInputField } from '../../entities/TextInputField'
import { SendButton } from '../../entities/SendButton'
import { type SyntheticEvent, useCallback, useState } from 'react'
import { type TFileStore, type TFileStoreCreationResult } from '../../pages/DocsPage/types'
import { Alert } from '@mui/material'
import { FileStoreManipulationService } from '../../shared/docs/services/file-store-manipulation-service'
export function FileStoreCreationForm () {
  const [nameFile, setNameFile] = useState('')
  const [banner, setBanner] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const onClickCreate = useCallback(async () => {
    const newFileStore: TFileStore = {
      name: nameFile
    }

    const fileStoreManipulationService = FileStoreManipulationService()
    const results: TFileStoreCreationResult = await fileStoreManipulationService.createFileStore(newFileStore)

    if (results.errorMessage) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage(results.errorMessage); return
    }
    setTimeout(() => { setResultMessage('') }, 5000)
    setResultMessage('Документ успешно создан')
  }, [nameFile])

  return <form className={styles.FileStoreCreationForm}>
        <TextInputField value={nameFile} className={styles.InputFields} id={'PlayerName'} label={'Первый заголовок'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setNameFile(e.currentTarget.value) }} />
        <div className={styles.WeightHeightRow}>
            <TextInputField className={styles.InputFields} type='file' id={'City'} label={''} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setBanner(e.currentTarget.value) }} />
        </div>
        <div className={styles.WeightHeightRow}>
            <SendButton className={styles.InputFields} label={'Очистить'} onClick={() => {}} />
            <SendButton className={styles.InputFields} label={'Создать'} onClick={onClickCreate} />
        </div>
        <div></div>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
    </form>
}
