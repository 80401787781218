import { makeEmptyCompetition } from '../objects'

const permissibleQuantity = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096]

export const takePermissibleQuantity = (currentPlayersQuantity: number) => {
  for (const num of permissibleQuantity) {
    if ((currentPlayersQuantity / 2) <= num) {
      return num
    }
  }
  return currentPlayersQuantity
}

export const takeEstimatedNumberOfTours = (wholeToursEvaluation: number) => {
  let estimatedCount = 0
  let remainder = wholeToursEvaluation
  while (remainder > 2 && remainder % 2 === 0) {
    remainder = remainder / 2
    estimatedCount++
  }

  return estimatedCount
}

export function takeBinaryDivideNumber(tourQuantity: number): number {
  let dividerNumber = 0
  let testValue = tourQuantity
  while(testValue > 1){
    testValue /= 2
    dividerNumber += 1
  }

  return dividerNumber
}

export function generateEmptyGrid (firstTourQuantity: number, gridLastSize: number, isEllimination: boolean) {
  if(!permissibleQuantity.includes(firstTourQuantity)){
    throw new Error('<Logic error>: Unacceptable grid size')
  }

  let toursQuantity = takeBinaryDivideNumber(firstTourQuantity) + 1
  if(isEllimination){
    toursQuantity = takeBinaryDivideNumber(firstTourQuantity) - takeBinaryDivideNumber(gridLastSize) + 1
  }

  return Array.from(Array(toursQuantity)).map((_, tid) => {
    const matchesCount = firstTourQuantity / (Math.pow(2, tid))
    return Array.from(Array(matchesCount)).map(_ => ({
      team1Names: [],
      team2Names: [],
      team1Score: [],
      team2Score: [],
      team1Addition: [],
      team2Addition: [],
      sets: [
        {
          scores: [makeEmptyCompetition()]
        }
      ]
    }))
  })

}

export function generateTournamentGrid (playersCount: number) {
  // const firstTourGames = (playersCount % 2 ? Math.ceil(playersCount / 2) + 1: playersCount / 2);
  const permissibleFirstQuantity = takePermissibleQuantity(playersCount)
  const toursNumberEstimation = takeEstimatedNumberOfTours(permissibleFirstQuantity) + 2

  return Array.from(Array(toursNumberEstimation)).map((_, tid) => {
    const matchesCount = Math.round(permissibleFirstQuantity / (tid > 0 ? 2 * tid : 1))
    return Array.from(Array(matchesCount)).map(_ => ({
      team1Names: [],
      team2Names: [],
      team1Score: [],
      team2Score: [],
      team1Addition: [],
      team2Addition: [],
      sets: [
        {
          scores: [makeEmptyCompetition()]
        }
      ]
    }))
  })
}

export function takeShortPlayerName (name: string) {
  const sp = name.split(' ')
  const firstLetter = sp[0][0]
  sp.shift()
  return `${firstLetter}. ${sp[0]}`
}
