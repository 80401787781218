import { type TPlayer } from '../PlayersPage/types'
import { type TCompetition } from '../../widgets/MatchDetailsManager/SetDetailManager'

export interface TSetScore {
  p1: number
  p2: number
  p1res?: string
  p2res?: string
}

export interface TSet {
  scores: TCompetition[]
}

export interface TTeamScore {
  score: number
  tiebreak: number
  sets: TSet[]
}

export type TTeamAddition = string[]

export interface TMatch {
  team1Names?: TPlayer[]
  team2Names?: TPlayer[]
  team1Score?: TTeamScore[]
  team2Score?: TTeamScore[]
  team1Addition: TTeamAddition
  team2Addition: TTeamAddition
  changeStartMatch?: string
  operatorId?: number
  hash?: number
  online?: boolean
  datetime?: string
}

export interface TTour {
  name: string
  matches: TMatch[]
}

export interface TTournament {
  id?: string
  name: string | null
  date: string | null
  tours: TTour[] | never
  gamers?: string
  wonType?: string
  type: string
  players: TPlayer[]
  internalPlayers: TPlayer[]
}

export interface TTournamentCreationResponse {
  status: number
  message?: string
  tournament: TTournament | null
}

export interface TTournamentListResponse {
  status: string | number
  tournaments: TTournament[]
}

export interface TTournamentCreationResult {
  errorMessage: string | null
  tournament: TTournament | null
}

export interface TTournamentCountResult {
  status: string | number
  message?: string
  count: number
}

export interface TTournamentsArrayMessage {
  errorMessage: string | null
  tournaments: TTournament[] | null
}

export enum ETournamentPageViewRegimes {
  Info,
  Players,
  Grid
}