export enum EGameStatements {
  undef,
  active,
  complete,
}

export enum EMatchStatuses {
  undef,
  active,
  setIsFinished,
  complete,
}

export interface TGameOpponent {
  name: string
}

export type TPlayerScore = 0 | 15 | 30 | 40 | /* AD */ 200 | /* GAME */ 100
export type TFirstPlayerScore = TPlayerScore
export type TSecondPlayerScore = TPlayerScore
export type TIsPlayerWin = boolean
export type TFirstPlayerIsWinner = TIsPlayerWin
export type TSecondPlayerIsWinner = TIsPlayerWin
export type TGamePlayerId = number
export type TGameId = number
export type TSetId = number
export type TGameTime = number
export type TGameAddition = number

export type TAction = [TFirstPlayerScore, TSecondPlayerScore, TFirstPlayerIsWinner, TSecondPlayerIsWinner, TGameAddition | undefined, TGameAddition | undefined, number]

export interface TGame {
  gameId: TGameId
  data: TAction[]
  gameWinner: TGamePlayerId
  holdsId: TGamePlayerId
}

export interface TSet {
  games: TGame[]
  setId: TSetId
}
