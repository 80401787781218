import './styles.css'
import { EAlign, EColumnType, TColumns } from '../data-grid/types'
import { DataGrid } from '../data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { type ITwoColumnsChooserArgs } from './types'
import { SearchBar } from '../search-bar'
import { searchFilter } from '../search-bar/search-service'
import { type TSearchQuery } from '../search-bar/types'
import { RootState } from '../../app/store'
import { connect, useDispatch } from 'react-redux'
import { setActivePlayers, setInternalPlayers } from '../../app/store/slices/editableTournament'
import { TPlayer } from '../../pages/PlayersPage/types'
import player, { setCountPlayers, setPlayers } from '../../app/store/slices/player'
import { PlayerManipulationService } from 'shared/player/services/player-manipulation-service'

const playerManipulationService = PlayerManipulationService()

const cols: TColumns[] = [
  {
    field: 'id',
    headerName: '',
    width: '30px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.string
  },
  {
    field: 'country',
    headerName: 'Страна',
    width: '30px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.flag
  },
  {
    field: 'name',
    headerName: 'Имя игрока',
    width: '300px',
    editable: false,
    align: EAlign.left,
    type: EColumnType.string
  }
]

const mapStateToProps = (state: RootState) => ({
  count: state.playerSlice.count,
  tournamentPlayers: state.editableTournamentSlice.internalPlayers,
  activePlayers: state.editableTournamentSlice.activePlayers,
  tours: state.editableTournamentSlice.tours,
  wholePlayers: state.playerSlice.players,
})

export const TwoColumnsChooser =  connect(mapStateToProps)(({ 
  count,
  tournamentPlayers,
  activePlayers,
  wholePlayers,
  tours,
 }: ITwoColumnsChooserArgs) => {
  
  const [searchQuery, setSearchQuery] = useState<TSearchQuery | null>(null)
  
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(setInternalPlayers([...tournamentPlayers, ...activePlayers].map((player) => wholePlayers.find((wholePlayer) => wholePlayer.name === player.name))))
    dispatch(setInternalPlayers([...tournamentPlayers, ...activePlayers]))
  },[wholePlayers])

  const appendToInternalPlayers = useCallback((player: any) => {
    // console.log('player: ', player)
    if(tournamentPlayers.find((p: TPlayer) => p.id === player.id)){
      return
    }

    // const [ targetPlayer ] = wholePlayers.filter((p: TPlayer) => p.id !== undefined && p.id === player.id)
    // console.log('targetPlayer: ', targetPlayer)
    dispatch(setInternalPlayers([...tournamentPlayers, player]))
  }, [tournamentPlayers, activePlayers, wholePlayers])

  const removeFromInternalPlayers = useCallback((player: any) => {
    dispatch(setInternalPlayers(tournamentPlayers.filter((p: TPlayer) => p.id !== undefined && p.id !== player.id)))
    dispatch(setActivePlayers(activePlayers.filter((p: TPlayer) => p.id !== undefined && p.id !== player.id)))
    /**
     * Добавить реализацию алгоритма поиска и удаления игрока из турнирной сетки 
     * Добавить реализацию алгоритма запрета на удаление игрока из турнирной сетки, если он уже играет
     */
  }, [tournamentPlayers, activePlayers, wholePlayers])

  const selectionVariants = cols.map(c => ({
    value: c.field,
    label: c.headerName || '',
    type: EColumnType.string
  }))

  const onSearchClick = useCallback(async (res: TSearchQuery) => {
    if (!res.value || !res.field || !res.equivalence) {
      dispatch(setPlayers([]))
      setSearchQuery(null)
    }
    setSearchQuery(res)
    if (res.field && res.value && res.value !== '') {
      const {count} = await playerManipulationService.getCountPlayersField(res.field, res.value)
      dispatch(setCountPlayers(count))
      const {players} = await playerManipulationService.getPlayersField(res.field, res.value)
      dispatch(setPlayers(players || []))
    } else {
      const {count} = await playerManipulationService.getCountPlayers()
      dispatch(setCountPlayers(count))
    } 
  }, [])

  const nextRows = async (page: number, perPageCount: number) => {
    if (searchQuery && searchQuery.value !== '') {
      return wholePlayers.slice(perPageCount * page, (perPageCount * page) + perPageCount)
    }

    return (await playerManipulationService.getPlayers(page, perPageCount))?.players
  }

  return <div className='TwoColumnsChooser'>
        <div></div>
        <SearchBar selectionVariants={selectionVariants} onSearchClick={onSearchClick}/>
        <DataGrid
            nextRows={async (page: number, perPageCount: number) => {
              return [...tournamentPlayers, ...activePlayers].slice(page * perPageCount, (page * perPageCount) + perPageCount)
            }}
            count={[...tournamentPlayers, ...activePlayers].length}
            rows={[...tournamentPlayers, ...activePlayers]}
            columns={cols}
            onRowDblClick={removeFromInternalPlayers}
            controls={true}
        />
        <DataGrid
            nextRows={nextRows}
            count={count}
            rows={wholePlayers}
            columns={cols}
            onRowDblClick={appendToInternalPlayers}
            controls={true}
        />
    </div>
})
