import { type IPerPageSelectorArgs } from './types'
import styles from './styles.module.css'
export const PerPageSelector = ({
  startIndex,
  endIndex,
  wholeLength
}: IPerPageSelectorArgs) =>

    <div className={styles.PerPageSelector}>
        {startIndex + 1} - {endIndex} из {wholeLength}
    </div>
