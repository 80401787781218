import React, {useState, useEffect} from 'react'
import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import { IconButton } from '../../entities/IconButton'
import SaveIcon from '../../icons/save.svg'

const settingsProvider = () => {

  const apiUrl = '/api/settings'

  return {
    takeCurrentSettings: async () => (await fetch(apiUrl)),
    save: async (settings: any) => (await fetch(apiUrl, {
        method: 'PATCH',
        body: JSON.stringify(settings),
        headers: {
          'Content-Type': 'application/json',
        }
      }))
  }
}

export function SettingsPage(){

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [updateInterval, setUpdateInterval] = useState(0)
  const [notification, setNotification] = useState('')

  useEffect(() => {
    (async () => {
        const settings = await settingsProvider().takeCurrentSettings()
        if(!settings.ok){
          return
        }
        const data = await settings.json()
        setLogin(data.sportStream365.login)
        setPassword(data.sportStream365.password)
        setUpdateInterval(data.parserInterval)
    })()
  }, [])

  const saveSettingsData = async () => {
    const res = await settingsProvider().save({
        sportStream365: {
          login,
          password,
        },
        parserInterval: updateInterval,
      })

    if(!res.ok){

    }

    setNotification('Сохранено')
    setTimeout(() => setNotification(''), 2000)

  }

  return <div className={styles.SettingsPage}>
    <AdminMenu />
    <div className={styles.SS365accountSettings}>
      <div className={styles.BlockHeader}>
        Настройки аккаунта SportStream365 для сбора информации о турнирах
      </div>
      <hr />
      <div className={styles.FormRow}>
      <div></div>
      <label for="login" >Логин</label>
      <input id="login" type="text" value={login} onChange={(e: any) => setLogin(e.target.value)} />
      </div>
      <div className={styles.FormRow}>
      <div></div>
      <label for="login" >Пароль</label>
      <input id="password" type="password" value={password} onChange={(e: any) => setPassword(e.target.value)} />
      </div>
      <div className={styles.FormRow}>
      <div></div>
      <label for="login" >Интервал обновления</label>
      <input id="refreshInterval" type="number" value={updateInterval} onChange={(e: any) => setUpdateInterval(e.target.value)} />
      </div>
      <div className={styles.FormRow}>
      <div></div>
      <div></div>
      <div className={styles.Notification}>{notification}</div>
      <div><IconButton icon={SaveIcon} onClick={saveSettingsData}/></div>
      </div>

      <hr />
    </div>

  </div>
}
