import { TActiveIndices, type TColumns, type TInternalRows } from '../types'

export const makeInternalRows = (rows: any[], cols: TColumns[]) => {
  const internalRows: TInternalRows = {}
  cols.forEach(col => (Object.assign(internalRows, { [col.field]: [] })))
  const dataKeys = Object.keys(internalRows)
  rows.forEach(row => {
    if (!row) {
      return
    }
    dataKeys.forEach((key: string) => {
      internalRows[key].push(row[key])
    })
  })
  return internalRows
}

export const takeJustSelectedRows = (rows: any[], perPageCount: number, page: number) => {
  return rows?.slice(perPageCount * page, perPageCount * (page + 1))
}
