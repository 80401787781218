// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_PlayersCreationForm__D-xiL{
    display: grid;
    grid-template-columns: 12fr;
}

.styles_InputFields__eTfwY {
    margin-top: 30px!important;
    margin-right: 30px!important;
    margin-left: 30px!important;
}
.styles_CountryCityRow__Hzncj{
    display: flex;
    justify-content: start;
}
.styles_CountryCityRow__Hzncj > * {
    width: 300px;
}
.styles_WeightHeightRow__-ZJvO{
    display: flex;
    justify-content: space-between;
}
.styles_WeightHeightRow__-ZJvO > * {
    width: 300px;
}
.styles_SelectorWrapper__8jgZf{
    padding-left: 50px;
    padding-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/operatores-creation-form/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".PlayersCreationForm{\n    display: grid;\n    grid-template-columns: 12fr;\n}\n\n.InputFields {\n    margin-top: 30px!important;\n    margin-right: 30px!important;\n    margin-left: 30px!important;\n}\n.CountryCityRow{\n    display: flex;\n    justify-content: start;\n}\n.CountryCityRow > * {\n    width: 300px;\n}\n.WeightHeightRow{\n    display: flex;\n    justify-content: space-between;\n}\n.WeightHeightRow > * {\n    width: 300px;\n}\n.SelectorWrapper{\n    padding-left: 50px;\n    padding-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlayersCreationForm": `styles_PlayersCreationForm__D-xiL`,
	"InputFields": `styles_InputFields__eTfwY`,
	"CountryCityRow": `styles_CountryCityRow__Hzncj`,
	"WeightHeightRow": `styles_WeightHeightRow__-ZJvO`,
	"SelectorWrapper": `styles_SelectorWrapper__8jgZf`
};
export default ___CSS_LOADER_EXPORT___;
