import { createSlice } from '@reduxjs/toolkit'

export const operatorsSlice = createSlice({
  name: 'operators',
  initialState: {
    operators: [],
    selectedOperators: [],
    count: 0
  },
  reducers: {
    setOperators: (state, action) => {
      return {
        ...state,
        operators: action.payload,
      }
    },
    setCountOperators: (state: any, action: {payload: number}) => {
      return {
        ...state,
        count: action.payload
      }
    },
    setSelectedOperators: (state, action) => ({
      ...state,
      selectedOperators: action.payload
    })
  }
})

export const {
  setOperators,
  setSelectedOperators,
  setCountOperators
} = operatorsSlice.actions
export default operatorsSlice.reducer
