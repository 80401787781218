import { useSelector } from 'react-redux'
import { type RootState } from '../app/store'

export function IsAdmin (props: any) {
  const loginOperator = useSelector((state: RootState) => state.loginSlice)

  if (loginOperator.role === 'administrator') {
    return <>{props.children}</>
  }
  return <></>
}

export function IsOperator (props: any) {
  const loginOperator = useSelector((state: RootState) => state.loginSlice)

  if (loginOperator.role === 'operator') {
    return <>{props.children}</>
  }
  return <></>
}

export function userIsOperator(){
  const loginOperator = useSelector((state: RootState) => state.loginSlice)

  if (loginOperator.role === 'operator') {
    return  true
  }
  return false
}


export function userIsAdmin(){
  const loginOperator = useSelector((state: RootState) => state.loginSlice)

  if (loginOperator.role === 'administrator') {
    return  true
  }
  return false
}