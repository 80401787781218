import AdminMenuObject from '../../shared/admin-menu'
import { List, ListItemIcon, ListSubheader } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { type TAdminMenuDescription } from './types'
import zIndex from '@mui/material/styles/zIndex'

export function AdminMenu () {
  const navigate = useNavigate()

  const makeAdminUI = useCallback((description: TAdminMenuDescription[]) => {
    return description.map((el: TAdminMenuDescription) => {
      return <ListItemButton key={el.link} onClick={() => { navigate(el.link || '') }}>
                <ListItemIcon>
                    <img src={el.icon} alt={'NoImg'}/>
                </ListItemIcon>
                <ListItemText>{el.name}</ListItemText>
            </ListItemButton>
    })
  }, [])

  const menuUi = makeAdminUI(AdminMenuObject())

  const listStyles = {
    width: '100%',
    maxWidth: 250,
    minWidth: 200,
    minHeight: '100%',
    bgcolor: 'background.paper',
    borderRight: '1px solid rgba(0,0,0,0.3)',
    position: 'relative',
    zIndex: 2
  }

  return <List
        sx={listStyles}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
            <ListSubheader component="div" id="nested-list-subheader">
                Ссылки
            </ListSubheader>
        }
    >
        {menuUi}
    </List>
}
