import { type TActionRecord } from '../action-record'
import { type TGamePlayerId } from '../types'

export enum ESetStatements {
  undef,
  active,
  tie,
  complete,
}

export interface TSetRecord {
  actionRecords: TActionRecord[]
  currentActionRecord: TActionRecord | null
  holds: TGamePlayerId
  win: TGamePlayerId
  fromSet: (setRecord: TSetRecord) => void
  makeAction: (pid: TGamePlayerId) => void
  getStatement: () => ESetStatements
  takeScore: () => number[]
  takeTieScore: () => number[]
  score: number[]
  tieScore: number[]
  setMatchScoreDict: any
  setTieScoreDict: any
  setHolds: any
  setWin: any
  makeFirstPlayerWin: any
  makeSecondPlayerWin: any
  switchToNextActionRecord: any
  switchStatementToActive: any
  switchStatementToComplete: any
  switchStatementToUndef: any
}
