import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import {
  DataGrid
} from '../../widgets/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { FileStoreManipulationService } from '../../shared/docs/services/file-store-manipulation-service'
import { type TFileStore, type TFileStoreDeleteMessage } from './types'
import { useNavigate } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { columns, mapDispatchToProps, mapStateToProps } from './maps'
import { connect } from 'react-redux'
import { IconMenu } from '../../widgets/icon-menu'
import fileStore from '../../app/store/slices/fileStore'
import { SearchBar } from '../../widgets/search-bar'
import { SearchPanel } from '../../widgets/search-bar/SearchPanel'
import { type TSearchQuery } from '../../widgets/search-bar/types'
import { searchFilter } from '../../widgets/search-bar/search-service'

const fileStoreManipulationService = FileStoreManipulationService()

export const FileStoreListPage: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  fileStores,
  selectedFileStores,
  setFileStores,
  setSelectedFileStores
}: any) {
  const [errorMessage, setErrorMessage] = useState('')
  const [localFileStores, setLocalFileStores] = useState(fileStores)
  const [searchQuery, setSearchQuery] = useState<TSearchQuery[]>([])
  const navigate = useNavigate()

  /**
     * @todo: Выполнить загрузку данных в редакс, с целью обхода множественного запроса всех данных
     */

  const loadWholeFileStores = async () => {
    const fileStores = await fileStoreManipulationService.takeWholeFileStores()
    if (fileStores.errorMessage) {
      setErrorMessage(fileStores.errorMessage); return
    }
    setFileStores(fileStores.fileStores || [])
  }

  useEffect(() => {
    if (!fileStores) {
      return
    }
    setLocalFileStores(searchFilter(fileStores, searchQuery))
  }, [searchQuery, fileStores])

  useEffect(() => {
    redirectIfNoRefreshToken(navigate);
    (async () => {
      await loadWholeFileStores()
    })()
  }, [])

  const deleteFileStore = useCallback(async () => {
    if (selectedFileStores.length === 0) {
      return
    }

    const clonedSelected = [...selectedFileStores]
    const interval = setInterval(async () => {
      if (clonedSelected.length === 0) {
        clearInterval(interval)
        await loadWholeFileStores(); return
      }

      const targetFileStore: TFileStore = clonedSelected.pop()
      setSelectedFileStores([])
      const fileStoreDeleteMessage: TFileStoreDeleteMessage = await fileStoreManipulationService.removeFileStore(targetFileStore.id || '')
      if (fileStoreDeleteMessage.errorMessage) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(fileStoreDeleteMessage.errorMessage); return
      }
      setErrorMessage(`Игрок ${targetFileStore.id} удален`)
    }, 1000)
  }, [selectedFileStores])

  const onEditObject = useCallback((newFileStoreData: any) => {
    const newFileStore = [...fileStores]
    newFileStore[newFileStoreData.keyId] = newFileStoreData
    delete newFileStore[newFileStoreData.keyId].keyId
    setFileStores(newFileStore)
  }, [fileStores, setFileStores])

  const onFileStoreRowSave = useCallback(async (fileStoresKey: number) => {
    const { errorMessage, status }: any = await fileStoreManipulationService.updateFileStore(fileStores[fileStoresKey])

    if (errorMessage) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage(errorMessage); return
    }
    setErrorMessage(`Пользователь ${fileStores[fileStoresKey].id} успешно обновлен`)
    setTimeout(() => { setErrorMessage('') }, 5000)
    await loadWholeFileStores()
  }, [fileStore])

  const onSearchQueryChange = useCallback((query: TSearchQuery[]) => {
    setSearchQuery(query)
  }, [])

  const onSelectionChange = (selection: string[]) => setSelectedFileStores(fileStores.filter((fileStore: TFileStore) => selection.includes(fileStore.id!)))

  return <div className={styles.FileStorePage}>
        <AdminMenu />
        <div className={styles.ListContainer}>
            <div className={styles.TableContainer}>
                <IconMenu
                    onSearchClick={() => {}}
                    onCreateClick={() => { navigate('/documents/create') }}
                    onDeleteClick={deleteFileStore}
                />
            </div>
        </div>

    </div>
})
