import { type TGameAddition, type TAction, TGamePlayerId, type TIsPlayerWin, type TPlayerScore } from '../types'
import { type TActionRecord } from './types'
import { AD, GAME, scoreSequence } from './constants'
/**
 * ActionRecord
 * Описывает объект турнирного действия. (игра до одного пойнта)
 * @returns
 */

export function ActionRecord (matchId: number): TActionRecord {
  let firstPlayerScore: TPlayerScore = 0
  let secondPlayerScore: TPlayerScore = 0
  let isFirstWinner: TIsPlayerWin = false
  const isSecondWinner: TIsPlayerWin = false
  let player1AAddition: TGameAddition | undefined
  let player2AAddition: TGameAddition | undefined

  /**
     * Установка очков для первого игрока
     * @param score
     */
  const setFirstPlayerScore = (score: TPlayerScore) => firstPlayerScore = score

  /**
     * Установка очков для второго игрока
     * @param score
     */
  const setSecondPlayerScore = (score: TPlayerScore) => secondPlayerScore = score

  /**
     * Установка первого игрока победителем
     */
  const setFirstPlayerWin = () => isFirstWinner = true

  /**
     * Установка второго игрока победителем
     */
  const setSecondPlayerWin = () => isFirstWinner = true

  /**
     * Инициализация данных взятых от другого объекта ActionRecord
     * @param actionRecord
     */
  function fromActionRecord (this: ReturnType<typeof ActionRecord>, actionRecord: TActionRecord) {
    this.firstPlayerScore = actionRecord.firstPlayerScore
    this.secondPlayerScore = actionRecord.secondPlayerScore
  }

  /**
     * Процедура увеличения очков
     */
  function firstPlayerScoreUp (this: ReturnType<typeof ActionRecord>) {
    /**
         * Защита от многократного обхода круга
         */
    if (this.firstPlayerScore === GAME || this.secondPlayerScore === GAME) {
      return GAME
    }

    this.isFirstWinner = true

    if (this.firstPlayerScore === 40 && this.secondPlayerScore === 40) {
      this.firstPlayerScore = AD
      return AD
    }

    /**
         * Это правильный код
         * Тут выиграл первый игрок. Если второй игрок проиграл имея счет AD, его счет возвращается
         * к 40
         */
    if (this.secondPlayerScore === AD) {
      this.secondPlayerScore = 40
      return this.firstPlayerScore
    }

    if (this.firstPlayerScore === AD) {
      this.firstPlayerScore = GAME
      return GAME
    }

    const targetScoreIndex = scoreSequence.indexOf(this.firstPlayerScore) + 1

    if (targetScoreIndex === scoreSequence.length) {
      this.firstPlayerScore = GAME
    } else {
      this.firstPlayerScore = scoreSequence[targetScoreIndex]
    }

    return this.firstPlayerScore
  }

  function secondPlayerScoreUp (this: ReturnType<typeof ActionRecord>) {
    /**
         * Защита от многократного обхода круга
         */
    if (this.secondPlayerScore === GAME || this.firstPlayerScore === GAME) {
      return GAME
    }

    this.isSecondWinner = true

    if (this.firstPlayerScore === 40 && this.secondPlayerScore === 40) {
      this.secondPlayerScore = AD
      return AD
    }

    /**
         * Это правильный код
         * Тут выиграл второй игрок. Если первый игрок проиграл имея счет AD, его счет возвращается
         * к 40
         */
    if (this.firstPlayerScore === AD) {
      this.firstPlayerScore = 40
      return this.firstPlayerScore
    }

    if (this.secondPlayerScore === AD) {
      this.secondPlayerScore = GAME
      return GAME
    }

    const targetScoreIndex = scoreSequence.indexOf(this.secondPlayerScore) + 1
    if (targetScoreIndex === scoreSequence.length) {
      this.secondPlayerScore = GAME
    } else {
      this.secondPlayerScore = scoreSequence[targetScoreIndex]
    }

    return this.secondPlayerScore
  }

  function toAction (this: ReturnType<typeof ActionRecord>) {
    return [
      this.firstPlayerScore,
      this.secondPlayerScore,
      this.isFirstWinner,
      this.isSecondWinner,
      this.player1AAddition,
      this.player2AAddition,
      matchId
    ] as TAction
  }

  return {
    /**
         * Pulic fields
         */
    firstPlayerScore,
    secondPlayerScore,
    isFirstWinner,
    isSecondWinner,
    player1AAddition,
    player2AAddition,

    /**
         * Private fields
         */
    toAction,
    setFirstPlayerScore,
    setSecondPlayerScore,
    setFirstPlayerWin,
    setSecondPlayerWin,
    fromActionRecord,
    firstPlayerScoreUp,
    secondPlayerScoreUp
  }
}
