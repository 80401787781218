// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestPage_ControlBar__BWMX2{
    display: flex;
    justify-content: center;
}

.TestPage_Control__OPIYr{
    padding: 15px;
    background-color: rgba(10, 30, 210, 0.8);
    color: #fff;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer
}`, "",{"version":3,"sources":["webpack://./src/pages/tests/TestPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,wCAAwC;IACxC,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB;AACJ","sourcesContent":[".ControlBar{\n    display: flex;\n    justify-content: center;\n}\n\n.Control{\n    padding: 15px;\n    background-color: rgba(10, 30, 210, 0.8);\n    color: #fff;\n    margin-left: 10px;\n    border-radius: 5px;\n    cursor: pointer\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ControlBar": `TestPage_ControlBar__BWMX2`,
	"Control": `TestPage_Control__OPIYr`
};
export default ___CSS_LOADER_EXPORT___;
