import { useEffect, useMemo, createRef, useState } from 'react'
import { MatchBeatsWidget } from '../../widgets/match-beats-widget'
import styles from './TestPage.module.css'
import { DoubleMatchRecord } from './libs/match-record/MatchRecord'
import { ActionRecord } from './libs/match-record/action-record/ActionRecord'
import { AD } from './libs/match-record/action-record/constants'

const GAME = 100
const BREAKPOINT = 300
const ACE = 1
const DOUBLE_FAULTS = 2

const isBreakPoint = ({ holds, set }: any) => {
  set = set?.map((s: any) => {
    if (holds === 0 && (s[1] === 40 || s[1] === AD)) {
      s[5] = BREAKPOINT
      return s
    }

    if (holds === 1 && (s[0] === 40 || s[0] === AD)) {
      s[4] = BREAKPOINT
      return s
    }

    return s
  })

  return set
}

const temmisSetStatCheckings = (action: any) => {
  return isBreakPoint(action)
}

export function TestPage () {
  const testMatch = useMemo(() => DoubleMatchRecord(), [])

  const [beats, setBeats] = useState<any>({
    opponents: [{
      name: 'Novak Djokovich'
    },
    {
      name: 'Carlos Alcarazs'
    }
    ],
    data: [],
    setName: 'TEST'
  })

  const onFirstPlayerClick = () => {
    testMatch.makeAction(0)
    onMatchTestClick()
  }

  const onSecondPlayerClick = () => {
    testMatch.makeAction(1)
    onMatchTestClick()
  }

  let holds = 0

  const onMatchTestClick = () => {
    const match = testMatch.takeSets()
    const sets = match.map(set => (
      {
        scoreDict: set.setMatchScoreDict,
        actionRecords: set.actionRecords,
        tieScore: set.setTieScoreDict
      }
    ))

    const data: any[] = []

    const setArray: any[] = []

    sets.forEach(({
      scoreDict,
      actionRecords,
      tieScore
    }: any) => {
      const setDict: any = {}

      actionRecords.forEach((action: any) => {
        const id = action.toAction()[6]
        if (!setDict[id]) {
          setDict[id] = []
        }
        setDict[id].push(action.toAction())
      })
      const ids = Object.keys(setDict)
      ids.forEach(i => {
        holds += 1
        data.push({
          holds: holds % 2,
          scores: temmisSetStatCheckings({
            set: setDict[i],
            holds: holds % 2
          }),
          results: scoreDict[i]
            ? {
                setScore: scoreDict[i],
                winner: 0,
                time: '00:00',
                holds: holds % 2,
                tie: tieScore[i] || null
              }
            : undefined
        })
      })

      setArray.push(setDict)
    })

    const nb = JSON.parse(JSON.stringify(beats))
    nb.data = data

    setBeats(nb)
  }

  return <div>
            <MatchBeatsWidget beats={beats}/>
        <div className={styles.ControlBar}>
            <div className={styles.Control} onClick={onFirstPlayerClick}>Действие на первого игрока</div>
            <div className={styles.Control} onClick={onSecondPlayerClick}>Действие на второго игрока</div>
            <div className={styles.Control} onClick={onMatchTestClick}>Тестирование матча</div>
        </div>
    </div>
}
