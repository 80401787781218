import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from '../../../app/store'
import { setSelectionParameters } from '../../../app/store/slices/editableTournament'
interface ITournamentGridToursTabsArgs {
  tourId: number | undefined,
  toursNumberEstimation: number
  permissibleFirstQuantity: number,
  isEllimination: boolean,
};

const mapStateToProps = (state: RootState) => ({
  tourId: state.editableTournamentSlice.selectableParameters.tourId,
  isEllimination: state.editableTournamentSlice.isEllimination,
})

export enum EMainTournamentTabNames {
  FIRST,
  SECOND,
  THIRD,
  HEMIFINAL,
  FINAL,
}

const mainTournamentTabLabels = {
  first: "Первый",
  second: "Второй",
  third: "Третий",
  hemifinal: "Полуфинал",
  final: "Финал",
}

export const TournamentGridToursTabs = connect(mapStateToProps)(({
  tourId = 0,
  toursNumberEstimation,
  permissibleFirstQuantity,
  isEllimination,
}: ITournamentGridToursTabsArgs) => {
  const dispatch = useDispatch()

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectionParameters({
      tourId: newValue
    }))
  }

  return <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    {!isEllimination && <Tabs aria-label="TournamentGridToursTabs" centered={true} onChange={handleChange} value={tourId}>
           <Tab label={mainTournamentTabLabels.first} value={EMainTournamentTabNames.FIRST} />
           <Tab label={mainTournamentTabLabels.second} value={EMainTournamentTabNames.SECOND} />
           <Tab label={mainTournamentTabLabels.third} value={EMainTournamentTabNames.THIRD} />
           <Tab label={mainTournamentTabLabels.hemifinal} value={EMainTournamentTabNames.HEMIFINAL} />
           <Tab label={mainTournamentTabLabels.final} value={EMainTournamentTabNames.FINAL} />
        </Tabs>}
        {isEllimination && <Tabs aria-label="TournamentGridToursTabs" centered={true} onChange={handleChange} value={tourId}>
            {/* <Tab label="-"/> */}
            <Tab label={`1/${(permissibleFirstQuantity / 2)}`} value={0} />
            {Array.from(Array(toursNumberEstimation)).map((_, id) => {
              const bottomIndex = (permissibleFirstQuantity / 2) / Math.pow(2, id + 1)
              return <Tab key={id + 1} label={`1/${bottomIndex}`} value={id + 1} />
            })}
        </Tabs>}
    </Box>
})
