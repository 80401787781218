import { createSlice } from '@reduxjs/toolkit'

export const matchesSlice = createSlice({
  name: 'matches',
  initialState: {
    matches: [],
    selectedMatches: []
  },
  reducers: {
    setMatches: (state, action) =>
      ({
        ...state,
        matches: action.payload
      }),
    setSelectedMatches: (state, action) => ({
      ...state,
      selectedMatches: action.payload
    })
  }
})

export const {
  setMatches,
  setSelectedMatches
} = matchesSlice.actions
export default matchesSlice.reducer
