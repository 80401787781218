import { useEffect } from "react"
import { setActivePlayers, setInternalPlayers, setTours, switchManualGridEdition } from "../../../../app/store/slices/editableTournament";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useDispatch } from "react-redux";
import { TTour } from "../../../../pages/TournamentsPage/types";
import { generateEmptyGrid } from "../../../../shared/tournaments/procedures";
import { TMatch } from "../../../../pages/TournamentsPage/types";import { TPlayer } from "../../../../pages/PlayersPage/types";
import { TouchRippleActions } from "@mui/material/ButtonBase/TouchRipple";

let switchAytoOffTimeout: ReturnType<typeof setTimeout>;

export const useOnChangeParamsAction = () => {

    const tours = useSelector((state: RootState) => state.editableTournamentSlice.tours)
    const manualGridEdition = useSelector((state: RootState) => state.editableTournamentSlice.manualGridEdition)
    const gridFirstSize = useSelector((state: RootState) => state.editableTournamentSlice.gridFirstSize)
    const gridLastSize = useSelector((state: RootState) => state.editableTournamentSlice.gridLastSize)
    const isEllimination = useSelector((state: RootState) => state.editableTournamentSlice.isEllimination)
    const internalPlayers = useSelector((state: RootState) => state.editableTournamentSlice.internalPlayers)
    const activePlayers = useSelector((state: RootState) => state.editableTournamentSlice.activePlayers)

    const dispatch = useDispatch()

    const mergeQualificationTournament = (small: any[], big: any[]) => {
        small.forEach((tour: any, tid: number) => {
            tour.forEach((match: any, mid: number) => {
                if(tid >= big.length){
                    return
                }
                big[tid][mid] = match;
            })
        })        
    }

    const mergeSmallTournamentIntoBigTournament = (small: any[], big: any[]) => {
        if(small.length >= big.length){
            return small
        }

        small.forEach((tour: any, tid: number) => {
            tour.forEach((match: any, mid: number) => {
                big[tid][mid] = match;
            })
        })        
    }

    const mergePartOfBigTournamentIntoSmall = (small: TMatch[][], big: TMatch[][]) => {

        const firstTour = big[0]
        const smallYLength = small[0].length

        let newInternalPlayers: TPlayer[] = [...internalPlayers]
        let newActivePlayers: TPlayer[] = [...activePlayers]

        
        firstTour.forEach((_: TMatch, mid: number) => {

            if( mid < smallYLength ){
                small[0][mid] = big[0][mid];
            }else{
                big[0][mid].team1Names.forEach((player: TPlayer) => {
                    newActivePlayers = newActivePlayers.filter((p: TPlayer) => p.id !== player.id)
                    newInternalPlayers.push(player)
                })

                big[0][mid].team2Names.forEach((player: TPlayer) => {
                    newActivePlayers = newActivePlayers.filter((p: TPlayer) => p.id !== player.id)
                    newInternalPlayers.push(player)
                })
            }
        })
        

        dispatch(setActivePlayers(newActivePlayers))
        dispatch(setInternalPlayers(newInternalPlayers))
    }


    useEffect(() => {
        
        if(switchAytoOffTimeout){
            clearTimeout(switchAytoOffTimeout)
        }

        if(!manualGridEdition){
            return
        }

        // switchAytoOffTimeout = setTimeout(() => dispatch(switchManualGridEdition(undefined)), 5000)

    }, [gridFirstSize])


    useEffect(() => {

        if(!manualGridEdition){
            return
        }

        const toursIsEmpty = tours.length === 0

        if(toursIsEmpty){
            dispatch(setTours(generateEmptyGrid(gridFirstSize, gridLastSize, isEllimination)))
            return
        }

        const firstToursLine: TTour[] = tours[0]
        const firstTourLineLength = firstToursLine.length

        if(gridFirstSize > firstTourLineLength){
            const newTours = generateEmptyGrid(gridFirstSize, gridLastSize, isEllimination)
            mergeSmallTournamentIntoBigTournament(tours, newTours)
            dispatch(setTours(newTours))
            return
        }

        if(gridFirstSize < firstTourLineLength){
            const newTours = generateEmptyGrid(gridFirstSize, gridLastSize, isEllimination)
            mergePartOfBigTournamentIntoSmall(newTours, tours)
            dispatch(setTours(newTours))
            return
        }

        if(gridFirstSize === firstTourLineLength){
            const newTours = generateEmptyGrid(gridFirstSize, gridLastSize, isEllimination)
            mergeQualificationTournament(tours, newTours)
            dispatch(setTours(newTours))
            return
        }


    }, [
        // tours,
        manualGridEdition,
        gridFirstSize,
        gridLastSize
    ]);
}