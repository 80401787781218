// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_TournamentPage__gcFFA{
    position: fixed;
    left: 0; top: 0; bottom: 0; right: 0;
    display: grid;
    grid-template-columns: 2fr 12fr;
    overflow: auto;
}
.styles_ListContainer__IQVLI{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.styles_ControlPanel__t-YeW{
    padding: 20px;;
    display: flex;
    justify-content: start;
}

.styles_ControlPanel__t-YeW > *{
     margin-left: 20px;
}

.styles_TableContainer__wjdNu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.styles_TableContainer__wjdNu > * {
    max-width: 1200px;
}

.styles_DataGrid__xHQV0{
    min-width: 60vmax;
}

.styles_SearchPanel__HR\\+8v{
    width: 80vmax;
}`, "",{"version":3,"sources":["webpack://./src/pages/TournamentsListPage/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO,EAAE,MAAM,EAAE,SAAS,EAAE,QAAQ;IACpC,aAAa;IACb,+BAA+B;IAC/B,cAAc;AAClB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;KACK,iBAAiB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".TournamentPage{\n    position: fixed;\n    left: 0; top: 0; bottom: 0; right: 0;\n    display: grid;\n    grid-template-columns: 2fr 12fr;\n    overflow: auto;\n}\n.ListContainer{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n.ControlPanel{\n    padding: 20px;;\n    display: flex;\n    justify-content: start;\n}\n\n.ControlPanel > *{\n     margin-left: 20px;\n}\n\n.TableContainer {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.TableContainer > * {\n    max-width: 1200px;\n}\n\n.DataGrid{\n    min-width: 60vmax;\n}\n\n.SearchPanel{\n    width: 80vmax;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TournamentPage": `styles_TournamentPage__gcFFA`,
	"ListContainer": `styles_ListContainer__IQVLI`,
	"ControlPanel": `styles_ControlPanel__t-YeW`,
	"TableContainer": `styles_TableContainer__wjdNu`,
	"DataGrid": `styles_DataGrid__xHQV0`,
	"SearchPanel": `styles_SearchPanel__HR+8v`
};
export default ___CSS_LOADER_EXPORT___;
