import styles from './styles.module.css'
import { TextInputField } from '../../entities/TextInputField'
import { SendButton } from '../../entities/SendButton'
import React, { type SyntheticEvent, useCallback, useState } from 'react'
import { PlayerManipulationService } from '../../shared/player/services/player-manipulation-service'
import { type TPlayer, type TPlayerCreationResult } from '../../pages/PlayersPage/types'
import { Alert } from '@mui/material'
export function PlayersCreationForm () {
  const [playerName, setPlayerName] = useState('')
  const [birdthDate, setBirdthDate] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [hand, setHand] = useState('')
  const [weight, setWeight] = useState('')
  const [height, setHeight] = useState('')
  const [avatar, setAvatar] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const [ countryValidationError, setCountryValidationError ] = useState('')
  const [ playerNameValidationError, setPlayerNameValidationError ] = useState('')

  function checkBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return true;
    } else {
      var k = 1024;
      var dm = decimals < 0 ? 0 : decimals;
      var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      var newBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
      if (i > 0 && newBytes > 700) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(`Размер файла: ${newBytes + ' ' + sizes[i]}; больше допустимого: 700 КБ`);
        return false
      }
      return true;
    }
  }

  const onClickCreate = useCallback(async () => {
    if(playerNameValidationError || countryValidationError) {
      return
    }

    const newPlayer: TPlayer = {
      name: playerName.trim(),
      country,
      city,
      birthDate: birdthDate,
      height,
      weight,
      plays: hand,
      avatar: avatar
    }

    const sizePhoto = new Blob([avatar]).size
    if(!checkBytes(sizePhoto)) {
      return
    }

    const playerManipulationService = PlayerManipulationService()
    const results: TPlayerCreationResult = await playerManipulationService.createPlayer(newPlayer)

    if (results.errorMessage) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage(results.errorMessage); return
    }
    setTimeout(() => { setResultMessage('') }, 5000)
    setResultMessage('Игрок успешно создан')
  }, [playerName, birdthDate, country, city, hand, weight, height, avatar, playerNameValidationError, countryValidationError])

  const reader = new FileReader()

  const nameValidation = () => {
    const commonCondition = /^[a-zA-Z\-` ]*/
    const doubleSpaceCondition = /  /

    if(doubleSpaceCondition.test(playerName)){
      setPlayerNameValidationError('В имени не должно быть двойных пробелов')
      return false
    }

    if(commonCondition.test(playerName)){
      setPlayerNameValidationError('')
      return true
    }
    setPlayerNameValidationError('Только имена на латинице. Разрешены - и ` а так же пробелы')
    return false
  }

  const countryValidation = () => {
    const reg = /^[A-Z]{2}$/
    if(!reg.test(country)){
      setCountryValidationError('Только двубуквенное обозначение большими латинскими буквами')
      return false
    }
    setCountryValidationError('')
    return true
  }

  return <form className={styles.PlayersCreationForm}>
        <TextInputField 
          value={playerName} 
          className={styles.InputFields} 
          id={'PlayerName'} 
          label={'Имя игрока'} 
          onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setPlayerName(e.currentTarget.value) }} 
          validation={nameValidation}
          errorText={playerNameValidationError}
          />
        <TextInputField value={birdthDate} className={styles.InputFields} id={'BirdDate'} label={'Дата рождения'} type={'date'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setBirdthDate(e.currentTarget.value) }} />
        <div className={styles.CountryCityRow}>
            <TextInputField 
              value={country} 
              className={styles.InputFields} 
              id={'Country'} 
              label={'Страна'} 
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setCountry(e.currentTarget.value) }} 
              validation={countryValidation}
              errorText={countryValidationError}
              />
            <TextInputField
              value={city} 
              className={styles.InputFields}
              id={'City'}
              label={'Город'}
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setCity(e.currentTarget.value) }} />
        </div>
        <TextInputField
          value={hand} 
          className={styles.InputFields}
          id={'Hand'}
          label={'Какой рукой играет? '}
          onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setHand(e.currentTarget.value) }} />
        <div className={styles.WeightHeightRow}>
            <TextInputField
              value={weight} 
              className={styles.InputFields} 
              id={'Weight'}
              label={'Вес'}
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setWeight(e.currentTarget.value) }} />
            <TextInputField
              value={height} 
              className={styles.InputFields}
              id={'Height'}
              label={'Рост'}
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setHeight(e.currentTarget.value) }} />
        </div>
        <input
            className={styles.InputFields}
            type='file'
            id={'input'}
            accept='image/*,.png,.jpg,.gif,.web'
            onChange={(event) => {
              // @ts-expect-error
              reader.readAsDataURL(event.target.files[0])
              reader.onloadend = () => {
                // @ts-expect-error
                setAvatar(reader.result)
              }
            }}/>
        <div className={styles.WeightHeightRow}>
            <SendButton className={styles.InputFields} label={'Очистить'} onClick={() => {}} />
            <SendButton className={styles.InputFields} label={'Создать'} onClick={onClickCreate} />
        </div>
        <div></div>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
    </form>
}
