import styles from './styles.module.css'
import { TextInputField } from '../../entities/TextInputField'
import { SendButton } from '../../entities/SendButton'
import React, { type SyntheticEvent, useCallback, useRef, useState } from 'react'
import { TNewsUpdateResult, type TNews, type TNewsCreationResult } from '../../pages/NewsPage/types'
import { Alert } from '@mui/material'
import { NewsManipulationService } from '../../shared/news/services/news-manipulation-service'
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from 'pages/NewsPage/maps'
import { useNavigate, useSearchParams } from 'react-router-dom'

const newsManipulationService = NewsManipulationService()

export const NewsCreationForm: any = connect(mapStateToProps, mapDispatchToProps)(function ({
  editableNews,
  setEditableNews
}: any) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const newsId = searchParams.get('id')
  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const setEditableNewsHeader1 = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ headers1: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsHeader2 = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ headers2: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsText = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ text: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsHeader1EN = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ headers1EN: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsHeader2EN = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ headers2EN: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsTextEN = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setEditableNews({ textEN: e.currentTarget.value }),
    [setEditableNews])

  const setEditableNewsBanner = useCallback((e: string) => setEditableNews({ banner: e }),[setEditableNews])

  const dateNews = () => {
    const date = new Date()
    return `${date.getDate()} ${date.toDateString().split(' ')[1].toUpperCase()} ${date.getFullYear()}`
  }

  function checkBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return true;
    } else {
      var k = 1024;
      var dm = decimals < 0 ? 0 : decimals;
      var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      var newBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
      if (i > 0 && newBytes > 700) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(`Размер файла: ${newBytes + ' ' + sizes[i]}; больше допустимого: 700 КБ`);
        return false
      }
      return true;
    }
  }

  const onClickCreate = useCallback(async () => {
    const newNews: TNews = {
      headers1: editableNews.headers1,
      headers2: editableNews.headers2,
      text: editableNews.text,
      headers1EN: editableNews.headers1EN,
      headers2EN: editableNews.headers2EN,
      textEN: editableNews.textEN,
      date: dateNews(),
      banner: editableNews.banner,
    }

    const sizePhoto = new Blob([editableNews.banner]).size
    if(!checkBytes(sizePhoto)) {
      return
    }
    
    if (Object.values(newNews).includes(null) || Object.values(newNews).includes('')) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage('Все данные должны быть заполнены'); return
    }

    if (newsId !== null) {
      const results: TNewsUpdateResult = await newsManipulationService.updateNews({ ...newNews, id: newsId })

      if (results.errorMessage) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(results.errorMessage); return
      }
      setTimeout(() => { setResultMessage('') }, 5000)
      setResultMessage('Новость успешно обновлена')
    } else {
      const results: TNewsCreationResult = await newsManipulationService.createNews(newNews)

      if (results.errorMessage) {
        setTimeout(() => { setErrorMessage('') }, 5000)
        setErrorMessage(results.errorMessage); return
      }
      navigate(window.location.search + '?id=' + results.news?.id)
      setTimeout(() => { setResultMessage('') }, 5000)
      setResultMessage('Новость успешно создана')
    }
  }, [editableNews, newsId])

  return <form className={styles.NewsCreationForm}>
    <div className={styles.NewsLanguage}>
      <TextInputField
        value={editableNews.headers1 ? editableNews.headers1 : ''}
        className={styles.InputFields}
        id={'Headers1'}
        label={'Первый заголовок'}
        onChange={setEditableNewsHeader1} />
      <TextInputField
        value={editableNews.headers1EN ? editableNews.headers1EN : ''}
        className={styles.InputFields}
        id={'Headers1'}
        label={'First title'}
        onChange={setEditableNewsHeader1EN} />
    </div>
    <div className={styles.NewsLanguage}>
      <TextInputField
        value={editableNews.headers2 ? editableNews.headers2 : ''}
        className={styles.InputFields}
        id={'Headers2'}
        label={'Второй заголовок'}
        onChange={setEditableNewsHeader2} />
      <TextInputField
        value={editableNews.headers2EN ? editableNews.headers2EN : ''}
        className={styles.InputFields}
        id={'Headers2'}
        label={'Second title'}
        onChange={setEditableNewsHeader2EN} />
    </div>
    <div className={styles.NewsLanguage}>
      <textarea
            value={editableNews.text ? editableNews.text : ''}
            className={styles.Textarea}
            onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setEditableNewsText(e) }}
            placeholder='Содержание'/>
      <textarea
            value={editableNews.textEN ? editableNews.textEN : ''}
            className={styles.Textarea}
            onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setEditableNewsTextEN(e) }}
            placeholder='Content'/>
    </div>
        <div className={styles.WeightHeightRow}>
            <input
                className={styles.InputFields}
                type='file'
                id={'input'}
                accept='image/*,.png,.jpg,.gif,.web'
                onChange={(event) => {
                  const reader = new FileReader()
                  // @ts-expect-error
                  reader.readAsDataURL(event.target.files[0])
                  reader.onloadend = () => {
                    // @ts-expect-error
                    setEditableNewsBanner(reader.result)
                  }
                }}/>
        
    </div>
        
    <div className={styles.WeightHeightRow}>
        <SendButton className={styles.InputFields} label={'Очистить'} onClick={() => setEditableNews(null)} />
        <SendButton className={styles.InputFields} label={newsId !== null ? 'Обновить' : 'Создать'} onClick={onClickCreate} />
    </div>
    <div></div>
    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
    </form>
})
