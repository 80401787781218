import { createSlice } from '@reduxjs/toolkit'
import { TPlayer } from 'pages/PlayersPage/types'

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    players: [],
    selectedPlayers: [],
    count: 0
  },
  reducers: {
    setPlayers: (state, action) =>
      ({
        ...state,
        players: action.payload
      }),
    setChankPlayers: (state: any, action: {payload: TPlayer[]}) => {
      return {
        ...state,
        players: [...state.players, ...action.payload]
      }
    },
    setCountPlayers: (state: any, action: {payload: number}) => {
      return {
        ...state,
        count: action.payload
      }
    },
    setSelectedPlayers: (state, action) => ({
      ...state,
      selectedPlayers: action.payload
    })
  }
})

export const {
  setPlayers,
  setChankPlayers,
  setCountPlayers,
  setSelectedPlayers
} = playersSlice.actions
export default playersSlice.reducer
