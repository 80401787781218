import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    role: undefined
  },
  reducers: {
    setLogin: (state: any, action: any) =>
      ({
        ...state,
        role: action.payload
      }),
    setRole: (state, action) => {
      const login = action.payload
      return {
        ...state,
        role: login

      }
    },
    setAdmin: (state, action) => {
      const admin = action.payload
      return {
        ...admin,
      }
    }
  }
})

export const { setLogin, setRole, setAdmin } = loginSlice.actions
export default loginSlice.reducer
