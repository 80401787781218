import { createSlice } from '@reduxjs/toolkit'

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    errorMessage: '',
    infoMessage: '',
  },
  reducers: {
    clearErrorMessage(state){
      return {
        ...state,
        errorMessage: ''
      }
    },
    clearInfoMessage(state){
      return {
        ...state,
        infoMessage: ''
      }
    },
    showErrorMessage(state, {payload}){
      return {
        ...state,
        errorMessage: payload,
      }
    },
    showInfoMessage(state, {payload}){
      return {
        ...state,
        infoMessage: payload,
      }
    }
  }
})

export const {
  clearErrorMessage,
  clearInfoMessage,
  showErrorMessage,
  showInfoMessage,
} = alertsSlice.actions
export default alertsSlice.reducer
