import { type Action, configureStore, type ThunkAction, applyMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import playerSlice from './slices/player'
import matchesSlice from './slices/match'
import tournamentsSlice from './slices/tournaments'
import editableTournamentSlice from './slices/editableTournament'
import messagesSlice from './slices/errors'
import { rootSaga } from './sagas/root'
import newsSlice from './slices/news'
import fileStoresSlice from './slices/fileStore'
import loginSlice from './slices/login'
import operatorsSlice from './slices/operators'
import alertSlice from './slices/alerts'

export const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    loginSlice,
    playerSlice,
    matchesSlice,
    tournamentsSlice,
    newsSlice,
    fileStoresSlice,
    messagesSlice,
    editableTournamentSlice,
    operatorsSlice,
    alertSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>
