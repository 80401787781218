import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import { NewsCreationForm } from '../../widgets/news-creation-form'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { IconMenu } from '../../widgets/icon-menu'
import { NewsManipulationService } from 'shared/news/services/news-manipulation-service'
import { useDispatch } from 'react-redux'
import { setEditableNews } from 'app/store/slices/news'

export function NewsPage () {
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const newsId = searchParams.get('id')

  const loadNews = async () => {
    if(!newsId){
      return
    }

    const tms = NewsManipulationService()
    const result = await tms.getNews(newsId)
    if(!result.errorMessage){
        dispatch(setEditableNews({
          ...result.news,
        }))
    }
  }

  useEffect(() => {
    loadNews()
  }, [])

  useEffect(() => {
    redirectIfNoRefreshToken(navigate)
  }, [])

  return <div className={styles.PlayerPage}>
        <AdminMenu />
        <div className={styles.ContentContainer}>
            <div className={styles.FormContainer}>
                <IconMenu
                    onSearchClick={() => { navigate('/news') }}
                    onDeleteClick={() => {}}
                    errorMessage={errorMessage}
                />
                <NewsCreationForm />
            </div>
        </div>
    </div>
}
