import { type TColumns } from '../widgets/data-grid/types'
import { type SyntheticEvent } from 'react'

export const collectObjectFromColumnsAndRows = (rows: any, columns: TColumns[], id: number) => {
  const object: any = {}
  columns.forEach(col => (object[col.field] = rows[col.field][id]))
  return object
}

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj))

export const disablePropagation = (e: SyntheticEvent<any>) => { e.stopPropagation() }

export const numberIsDefined = (n: number | string | undefined) => typeof n === 'number'

const twoDigitsFormat = (val: number) => {

  const strVal = String(val)

  if(strVal.length === 1){
    return '0' + val
  }
  return val
}

const formatDate = (date: Date) => 
`${date.getFullYear()}-${twoDigitsFormat(date.getMonth() + 1)}-${twoDigitsFormat(date.getDate())}T${twoDigitsFormat(date.getHours())}:${twoDigitsFormat(date.getMinutes())}`


const takeGMTParams = () => {
  const currentDate = new Date()
  const timeZoneOffsetInMinutes = -currentDate.getTimezoneOffset()
  const timeZoneHoursPart = Math.ceil(timeZoneOffsetInMinutes / 60)
  const timeZoneMinutesPart = timeZoneOffsetInMinutes - (timeZoneHoursPart * 60)

  return {
    timeZoneOffsetInMinutes,
    timeZoneHoursPart,
    timeZoneMinutesPart,
  }
}


export const updateTZMinutesDifference = (targetDate: Date, minutesDelta: number) => {
  if ( minutesDelta < 0 ){
    targetDate.setHours(targetDate.getHours() - 1)
    targetDate.setMinutes(60 - (-minutesDelta))
  }
}

export const returnTZMinutesDifference = (targetDate: Date, minutesDelta: number) => {
  if ( minutesDelta > 60 ){
    targetDate.setHours(targetDate.getHours() - 1)
    targetDate.setMinutes(minutesDelta - 60)
  }
}

export const switchTimeZone = (targetDate: Date, tzHours: number, tzMinutes: number) => {
  if ( tzHours < 0 ){
    targetDate.setDate(targetDate.getDate() - 1)
    targetDate.setHours(24 - (-tzHours))
    updateTZMinutesDifference(targetDate, tzMinutes)
    return targetDate
  }

  if ( tzHours >= 24 ){
    targetDate.setDate(targetDate.getDate() + 1)
    targetDate.setHours(tzHours - 24)
    returnTZMinutesDifference(targetDate, tzMinutes)
    return targetDate
  }

  updateTZMinutesDifference(targetDate, tzMinutes)
  returnTZMinutesDifference(targetDate, tzMinutes)

  targetDate.setHours(tzHours)
  targetDate.setMinutes(targetDate.getMinutes() - tzMinutes)
  return targetDate
} 

export const getGMTDate = (val: string) => {
  const targetDate = new Date(val)
  
  const {
    timeZoneHoursPart,
    timeZoneMinutesPart,
  } = takeGMTParams()

  const targetTimeZoneHours = targetDate.getHours() - timeZoneHoursPart
  const targetTimeZoneMinutes = targetDate.getMinutes() - timeZoneMinutesPart

  return formatDate(switchTimeZone(targetDate, targetTimeZoneHours, targetTimeZoneMinutes))
}

export const fromGMTDate = (val: string) => {

  if(!val){
    return ''
  }
  const targetDate = new Date(val)
  
  const {
    timeZoneHoursPart,
    timeZoneMinutesPart,
  } = takeGMTParams()

  const targetTimeZoneHours = targetDate.getHours() + timeZoneHoursPart
  const targetTimeZoneMinutes = targetDate.getMinutes() + timeZoneMinutesPart

  return formatDate(switchTimeZone(targetDate, targetTimeZoneHours, targetTimeZoneMinutes))

}
