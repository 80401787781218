import styles from '../../styles.module.css'
import { type SyntheticEvent } from 'react'
import { type TPlayer } from '../../../../../pages/PlayersPage/types'
import { takeShortPlayerName } from '../../../../../shared/tournaments/procedures'
import CupIcon from '../../../../../icons/cup.svg'
import { type TTeamScore } from '../../index'
import { numberIsDefined } from '../../../../../shared/procedures'
import classNames from 'classnames'
import { type TTeamAddition } from '../../../../../pages/TournamentsPage/types'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../../../app/store'

interface ITeamLineArgs {
  selected: boolean
  teamSelected: boolean
  addition: TTeamAddition
  onTeamLineClick?: (e: SyntheticEvent<HTMLDivElement, Event>) => void
  team: TPlayer[]
  teamScore?: TTeamScore[]
  onPlayerDblClick?: (player: TPlayer) => void
  onRetClick?: () => void
}

type TAdditionDict = Record<string, JSX.Element>

const additionDictionary: TAdditionDict = {
  cup: <img src={CupIcon} alt='no'/>,
  disqualification: <div>DIS</div>,
  next: <div>{'-->'}</div>
}

export function TeamLine ({
  selected,
  teamSelected,
  addition,
  onTeamLineClick,
  team,
  teamScore,
  onPlayerDblClick,
  onRetClick
}: ITeamLineArgs) {
  const loginOperator = useSelector((state: RootState) => state.loginSlice)
  const admin = loginOperator.role === 'administrator'

  const middleStyles = classNames([
    styles.AchievementIcons
  ], {
    [styles.SelectableElement]: selected && admin,
    // [styles.SelectedElement]: midlineSelected && admin
  })

  return <div className={styles.TournamentGreedCardLine}>
        <div className={styles.TeamLine + ' ' + (selected && admin && styles.SelectableElement) + ' ' + (teamSelected && admin && styles.SelectedElement)} onClick={onTeamLineClick}>
            {team.map((player: TPlayer) => <div key={player.name} className={styles.TeamPlayerLine} onDoubleClick={onPlayerDblClick && (() => { onPlayerDblClick(player) })}>
                <div className={`react-tel-input flag ${player?.country?.toLowerCase()} ${styles.flag}`}></div>
                <div className={styles.PlayerName}>{takeShortPlayerName(player.name)}</div>
            </div>)}
        </div>
        <div className={middleStyles} onClick={onRetClick}>
            <div className={addition.includes("ret") ? styles.Ret + ' ' + styles.RetActive : styles.Ret}>Ret</div>
        </div>
        <div className={styles.Scores}>
            {teamScore?.map((score, id) => score && <div key={score.score + ' ' + id} className={styles.ScoreNumber}>
                {score.score}
                {numberIsDefined(score.tiebreak) && score.tiebreak !== 0 && <div key={score.score + ' ' + score.tiebreak} className={styles.TieBreak}>{score.tiebreak}</div>}
            </div>)}
        </div>
    </div>
}
