import classNames from 'classnames'
import { TextInputField } from '../../entities/TextInputField'
import styles from './styles.module.css'
import { SendButton } from '../../entities/SendButton'
import { type SyntheticEvent, useCallback, useState } from 'react'
import { AuthService } from './auth-service'
import { Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { type TAuthPackage } from './types'
import { useDispatch } from 'react-redux'
import { setAdmin, setRole } from '../../app/store/slices/login'

const FORBIDDEN_STATUS = 'forbidden'

export function AuthPage () {
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const rootClasses = classNames([
    styles.AuthPage
  ])

  const formClasses = classNames([
    styles.AuthPageForm
  ])

  const footerClasses = classNames([
    styles.FormItem,
    styles.Footer
  ])

  const onLoginChange = useCallback((e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setLogin(e.currentTarget.value || '') }, [login])
  const onPasswordChange = useCallback((e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setPassword(e.currentTarget.value || '') }, [password])
  const onLoginClick = useCallback(async () => {
    const authService = AuthService()
    const authRes: TAuthPackage = await authService.auth(login, password)
    if (authRes.status === FORBIDDEN_STATUS) {
      setErrorMessage('Авторизация не удалась. Неверные данные')
      return setTimeout(() => { setErrorMessage('') }, 5000)
    }

    /**
         * Невозможная ситуация в нормально работающей системе
         * По этому если она происходит, выполняем краш
         */
    if (!authRes.admin?.refreshToken) {
      throw new Error('Logic error: <refresh token is empty>');
    }
    if (!authRes.admin?.role) {
      throw new Error('Logic error: <role is empty>');
    }

    window.localStorage.setItem('refreshToken', authRes.admin?.refreshToken)
    window.localStorage.setItem('role', authRes.admin?.role)

    dispatch(setAdmin(authRes.admin))
    
    if (authRes.admin.role === 'operator') {
      navigate('/tournaments'); return
    }
    navigate('/players')
  }, [login, password])

  return <div className={rootClasses}>
        <form className={formClasses}>
            <h1><span>ATT-TOUR</span></h1>
            <h3>Админ Панель</h3>
            <TextInputField value={login} className={styles.FormItem} id={'asdfasdf'} label={'Login'} onChange={onLoginChange} />
            <TextInputField value={password} className={styles.FormItem} id={'asdfasdfgdf'} label={'Password'} type={'password'} onChange={onPasswordChange} />
            <SendButton className={styles.FormItem} label={'Войти'} onClick={onLoginClick}/>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <footer className={footerClasses}>@2023</footer>
        </form>
    </div>
}
