// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_TournamentGridEditor__6yZHZ{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    min-height: 1200px;
}
.styles_EditorFrame__AM5A9{
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin-top: 50px;
}
.styles_Cards__XmJB5 > * {
    margin: 30px;
}
.styles_LeftHemiScreen__n\\+lKQ{
    background: aquamarine;
}
.styles_RightHemiScreen__1qgON{
    background: antiquewhite;
}
.styles_TableBar__YV8P1{
    display: flex;
}
.styles_GamerTable__LHifc{
    display: flex;
    flex-direction: column;
}
.styles_GamerTableRow__WkCs\\+{
    display: flex;
    flex-direction: row;
}
.styles_GamerTableRow__WkCs\\+ > *{
    margin: 10px;
}
.styles_GamerTableRow__WkCs\\+:hover {
    background: rgba(210, 210, 210, 0.1);
}

.styles_PlayersListContainer__3XFER{
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/widgets/tournament/tournament-grid-editor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".TournamentGridEditor{\n    display: flex;\n    flex-direction: column;\n    margin-top: 50px;\n    min-height: 1200px;\n}\n.EditorFrame{\n    display: grid;\n    grid-template-columns: 6fr 6fr;\n    margin-top: 50px;\n}\n.Cards > * {\n    margin: 30px;\n}\n.LeftHemiScreen{\n    background: aquamarine;\n}\n.RightHemiScreen{\n    background: antiquewhite;\n}\n.TableBar{\n    display: flex;\n}\n.GamerTable{\n    display: flex;\n    flex-direction: column;\n}\n.GamerTableRow{\n    display: flex;\n    flex-direction: row;\n}\n.GamerTableRow > *{\n    margin: 10px;\n}\n.GamerTableRow:hover {\n    background: rgba(210, 210, 210, 0.1);\n}\n\n.PlayersListContainer{\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TournamentGridEditor": `styles_TournamentGridEditor__6yZHZ`,
	"EditorFrame": `styles_EditorFrame__AM5A9`,
	"Cards": `styles_Cards__XmJB5`,
	"LeftHemiScreen": `styles_LeftHemiScreen__n+lKQ`,
	"RightHemiScreen": `styles_RightHemiScreen__1qgON`,
	"TableBar": `styles_TableBar__YV8P1`,
	"GamerTable": `styles_GamerTable__LHifc`,
	"GamerTableRow": `styles_GamerTableRow__WkCs+`,
	"PlayersListContainer": `styles_PlayersListContainer__3XFER`
};
export default ___CSS_LOADER_EXPORT___;
