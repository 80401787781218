import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { IconMenu } from '../../widgets/icon-menu'
import { TournamentsCreationForm } from '../../widgets/tournament/tournament-creation-form'
import { TwoColumnsChooser } from '../../widgets/two-columns-chooser'
import { type TPlayer } from '../PlayersPage/types'
import { useAppSelector } from '../../app/hooks'
import { EAlign, EColumnType, type TColumns } from '../../widgets/data-grid/types'
import { connect, useDispatch, useSelector } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from './maps'
import { TournamentGridEditor } from '../../widgets/tournament/tournament-grid-editor'
import { generateTournamentGrid } from '../../shared/tournaments/procedures'
import { type RootState } from '../../app/store'
import { TournamentManipulationService } from '../../shared/tournaments/services/tournament-manipulation-service'
import { IsAdmin, IsOperator } from '../../shared/checkUser'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { ETournamentPageViewRegimes } from './types';
import { setEditableTournamentFields } from '../../app/store/slices/editableTournament'
import { showInfoMessage, clearInfoMessage, showErrorMessage, clearErrorMessage } from 'app/store/slices/alerts'
import { CollectionsOutlined } from '@mui/icons-material'



export const TournamentsPage = connect(mapStateToProps, mapDispatchToProps)(
  function ({
    players,
    tours,
    setTournamentAttributes

  }: any) {
    const [errorMessage, setErrorMessage] = useState('')
    const [loadingTour, setLoadingTour] = useState<boolean>(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const tournamentId = searchParams.get('id')
    const wholePlayers = useAppSelector(store => store.playerSlice.players)
    const [tournamentPageViewRegime, setTournamentPageViewRegime] = useState<ETournamentPageViewRegimes>(ETournamentPageViewRegimes.Info)
    const dispatch =  useDispatch()

    const editableTournament = useSelector((state: RootState) => state.editableTournamentSlice)

    const loadTournament = async () => {

      if(!tournamentId){
        return
      }

      setLoadingTour(true)

      const tms = TournamentManipulationService()
      const result = await tms.getTournament(tournamentId)
      if(!result.errorMessage){ 
        dispatch(setEditableTournamentFields({
            ...result.tournament,
          }))

        if(result.tournament.isEllimination){
          dispatch(
            setEditableTournamentFields(
              {
                gridFirstSize: result.tournament.tours[0]?.length || 8,
                gridLastSize: result.tournament.tours.at(-1)?.length || 2
              }
            )
          )
        }

      }

      setLoadingTour(false)
    }

    useEffect(() => {
      loadTournament()
    }, [])

    useEffect(() => {
      redirectIfNoRefreshToken(navigate)
    }, [])
    useEffect(() => {
    }, [players, tours])

    const saveTournamentToLocalStorage = async () => {

      if(!editableTournament.canSave){
        dispatch(showErrorMessage('Заполните все необходимые поля'))
        setTimeout(() => { dispatch(clearErrorMessage()) }, 5000)
        return
      }

      const tms = TournamentManipulationService()

      if (tournamentId) {
        const {errorMessage, tournament}: any = await tms.updateTournament(editableTournament)
        
        if(errorMessage){
          dispatch(showErrorMessage(`Ошибка обновления турнира: ${errorMessage}`))
          setTimeout(() => { dispatch(clearErrorMessage()) }, 5000) 
          return
        }

        dispatch(setEditableTournamentFields(tournament))
        dispatch(showInfoMessage(`Турнир успешно обновлен`))
        setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
        loadTournament()

      } else {
        const creationResult: any = await tms.createTournament(editableTournament)

        if(creationResult.errorMessage){
          dispatch(showErrorMessage(`Ошибка создания турнира: ${creationResult.errorMessage}`))
          setTimeout(() => { dispatch(clearErrorMessage()) }, 5000) 
          return
        }
        
        dispatch(setEditableTournamentFields(creationResult.tournament))
        navigate(window.location.search + '?id=' + creationResult.tournament.id)
        dispatch(showInfoMessage(`Турнир ${creationResult.tournament.id} успешно создан`))
        setTimeout(() => { dispatch(clearInfoMessage()) }, 5000)
      }
    }

    const changeTab = useCallback((targetRegime: ETournamentPageViewRegimes) => {
      setTournamentPageViewRegime(targetRegime)
    }, [])

    /// /////////////////////

    return <div className={styles.TournamentPage}>
        <AdminMenu />
        <div className={styles.ContentContainer}>
            <div className={styles.FormContainer}>
                <IsAdmin>
                    <IconMenu
                        onSearchClick={() => { navigate('/tournaments') }}
                        onDeleteClick={() => {}}
                        onSaveClick={saveTournamentToLocalStorage}
                    />

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={tournamentPageViewRegime} onChange={(_, targetValue) => changeTab(targetValue)} aria-label="basic tabs example">
                        <Tab value={ETournamentPageViewRegimes.Info} label="Информация о турнире" />
                        <Tab value={ETournamentPageViewRegimes.Players} label="Игроки" />
                        <Tab value={ETournamentPageViewRegimes.Grid} label="Турнирная сетка" />
                      </Tabs>
                    </Box>

                    {tournamentPageViewRegime === ETournamentPageViewRegimes.Info && 
                      <TournamentsCreationForm /> }
                    {tournamentPageViewRegime === ETournamentPageViewRegimes.Players && 
                      <TwoColumnsChooser /> }
                    {tournamentPageViewRegime === ETournamentPageViewRegimes.Grid && 
                      <TournamentGridEditor />}
                </IsAdmin>

                <IsOperator>
                    <TournamentGridEditor />
                </IsOperator>
            </div>
            {loadingTour && <div className={styles.loading}><CircularProgress size="4rem" /></div>}
        </div>
    </div>
  })
