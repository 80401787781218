import { privateDecrypt } from 'crypto'
import { HoldSign } from '../../../../../widgets/match-beats-widget/svg'
import { ActionRecord, type TActionRecord } from '../action-record'
import { FIRST_PLAYER, SECOND_PLAYER } from '../constants'
import { EGameStatements, type TGamePlayerId, TGame } from '../types'
import { ESetStatements, type TSetRecord } from './types'
import { current, Action } from '@reduxjs/toolkit'
import { GAME } from '../action-record/constants'

type TStatementHandlerDict = Record<number, (pid: TGamePlayerId) => void>

/**
 * Описание сета.
 * Сет эквивалентен одному столбцу очков на карточке турнира
 */
export function SetRecord (): TSetRecord {
  const actionRecords: TActionRecord[] = []

  let setStatement: ESetStatements = ESetStatements.undef
  let currentActionRecord: TActionRecord | null = null
  const holds: TGamePlayerId = -1 // Подающий один на весь сет
  let win: TGamePlayerId = -1 // Победитель один на весь сет
  const setMatchScoreDict: any = {}
  const setTieScoreDict: any = {}

  const score = [0, 0]
  const tieScore = [0, 0]

  let matchId = 0

  /**
     * Инициализация данных из другого объекта сета
     */
  const fromSet = (setRecord: TSetRecord) => {
    const recentScore = setRecord.takeScore()
    score[0] = recentScore[0]
    score[1] = recentScore[1]
  }

  const setCheckingsToComplete = () => {
    /**
         * Основная процедура работы с очками в сете
         * Одна колонка счета в карточке игрока
         */

    /**
         *  Управление счетом в состоянии тай брейка
         */

    if (setStatement === ESetStatements.tie) {
      const tieDelta = Math.abs(tieScore[0] - tieScore[1])
      const tieDeltaSign = Math.sign(tieScore[0] - tieScore[1])

      if ((tieScore[0] === 7 || tieScore[1] === 7) && tieDelta > 2) {
        if (tieDeltaSign > 0) {
          win = 0
        } else {
          win = 1
        }

        return true
      }
    }

    /**
         * тай брейк переключение
         */
    if (score[0] === score[1] && score[0] === 6) {
      setStatement = ESetStatements.tie
      return false
    }

    /**
         * Состояние победы
         * Не в тай брейке
         */
    if ((score[0] >= 6 || score[1] >= 6) && setStatement === ESetStatements.active) {
      const delta = score[0] - score[1]

      if (delta >= 2) {
        win = 0
        return true
      }

      if (delta <= -2) {
        win = 1
        return true
      }
    }

    return false
  }

  /**
     * Стандартная манипуляция когда матч активен
     * @param pid
     */
  const makeActiveMatchAction = (pid: TGamePlayerId) => {
    /**
         * Обрыв любых действий при завершении
         */
    if (setStatement === ESetStatements.complete) {
      return
    }

    const previousRecord = currentActionRecord
    currentActionRecord = ActionRecord(matchId)
    if (previousRecord) {
      currentActionRecord.fromActionRecord(previousRecord)
    }
    actionRecords.push(currentActionRecord)

    /**
         * Ситуации, которые могут быть на этом моменте
         * 1) Счет 40:40 и мы сделаем результат А
         * 2) Счет 40:<40 и тогда мы сделаем победителем того, кто прошел отметку
         */

    let result
    if (pid === 0) {
      result = currentActionRecord.firstPlayerScoreUp()
    } else {
      result = currentActionRecord.secondPlayerScoreUp()
    }

    /**
         * Обработка состояния обычной игры
         */
    if (result === GAME) {
      score[pid] = score[pid] + 1
      setMatchScoreDict[matchId] = [...score]
      setTieScoreDict[matchId] = [...tieScore]
      matchId += 1
      currentActionRecord = null
    }

    /**
         * Проверка результатов сета на завершенность
         */
    if (setCheckingsToComplete()) {
      /**
             * Выполнение процедуры завершения сета
             */
      setStatement = ESetStatements.complete
      switchStatementToComplete()
      /**
             * После этого процедуру по созданию нового сета и переключения на него
             * берет на себя слой выше
             */
    }
  }

  const makeTieBreak = (pid: TGamePlayerId) => {
    /**
         * Обрыв любых действий при завершении
         */
    if (setStatement === ESetStatements.complete) {
      return
    }

    const previousRecord = currentActionRecord
    currentActionRecord = ActionRecord(matchId)
    if (previousRecord) {
      currentActionRecord.fromActionRecord(previousRecord)
    }
    actionRecords.push(currentActionRecord)

    /**
         * Ситуации, которые могут быть на этом моменте
         * 1) Счет 40:40 и мы сделаем результат А
         * 2) Счет 40:<40 и тогда мы сделаем победителем того, кто прошел отметку
         */

    let result
    if (pid === 0) {
      result = currentActionRecord.firstPlayerScoreUp()
    } else {
      result = currentActionRecord.secondPlayerScoreUp()
    }

    /**
         * Обработка состояния тай брейка
         */
    if (result === GAME) {
      tieScore[pid] += 1
      setMatchScoreDict[matchId] = [...score]
      setTieScoreDict[matchId] = [...tieScore]
      matchId += 1
      currentActionRecord = null
    }

    /**
         * Проверка результатов сета на завершенность
         */
    if (setCheckingsToComplete()) {
      /**
             * Выполнение процедуры завершения сета
             *
             * Так как тут победа в тай брейке, мы должны плюсануть
             * очко тому, кто победил
             */

      if (tieScore[0] > tieScore[1]) {
        score[0] += 1
      } else {
        score[1] += 1
      }
      setMatchScoreDict[matchId - 1] = [...score]

      setStatement = ESetStatements.complete
      switchStatementToComplete()
      /**
             * После этого процедуру по созданию нового сета и переключения на него
             * берет на себя слой выше
             */
    }
  }

  /**
     * Словарь обработки состояний сета
     */
  const statementHandlerDict: TStatementHandlerDict = {
    [ESetStatements.undef]: () => {},
    [ESetStatements.active]: makeActiveMatchAction,
    [ESetStatements.tie]: makeTieBreak,
    [ESetStatements.complete]: () => {}
  }

  /**
     * Выполняет элементарное теннисное действие
     */
  const makeAction = (pid: TGamePlayerId) => {
    statementHandlerDict[setStatement](pid)
  }

  /**
     * Установка подающего
     */
  function setHolds (this: ReturnType<typeof SetRecord>, pid: number) { this.holds = pid };

  /**
     * Действие победителей
     */
  const setWin = (pid: number) => win = pid
  const makeFirstPlayerWin = () => setWin(FIRST_PLAYER)
  const makeSecondPlayerWin = () => setWin(SECOND_PLAYER)
  const getStatement = () => setStatement

  /**
     * Переключение на новую запись теннисного действия
     */
  const switchToNextActionRecord = () => {
    const newAction: TActionRecord = ActionRecord(matchId)
    currentActionRecord = newAction
    actionRecords.push(newAction)
  }

  const switchStatementToActive = () => {
    setStatement = ESetStatements.active
  }
  const switchStatementToComplete = () => {
    setStatement = ESetStatements.complete
  }
  const switchStatementToUndef = () => {
    setStatement = ESetStatements.undef
  }

  const takeScore = () => score
  const takeTieScore = () => tieScore

  return {
    actionRecords,
    currentActionRecord,
    holds,
    win,
    score,
    tieScore,
    setMatchScoreDict,
    setTieScoreDict,
    takeScore,
    takeTieScore,
    fromSet,
    makeAction,
    setHolds,
    setWin,
    makeFirstPlayerWin,
    makeSecondPlayerWin,
    switchToNextActionRecord,
    switchStatementToActive,
    switchStatementToComplete,
    switchStatementToUndef,
    getStatement
  }
}
