import { type IEditPanelArgs } from './types'
import PencilIcon from '../../../../icons/pencil.svg'
import DeleteIcon from '../../../../icons/remove.svg'
import { IconButton } from '../../../../entities/IconButton'
export function EditPanel ({ onEdit, onDelete }: IEditPanelArgs) {
  return <div style={{
    display: 'flex',
    flexDirection: 'row'
  }}>
        <IconButton icon={PencilIcon} onClick={onEdit && onEdit} />
        <IconButton icon={DeleteIcon} onClick={onDelete && onDelete}/>
    </div>
}
