import styles from './styles.module.css'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Divider, InputBase, Paper } from '@mui/material'
import { type SyntheticEvent, useCallback, useState, useEffect } from 'react'
import { SelectionMenu } from '../../entities/SelectionMenu'
import { type ISearchBarArgs } from './types'
import { EColumnType } from '../data-grid/types'
import { type TSelectionVariant } from '../../entities/SelectionMenu/types'

const stringEquivalenceVariants = [
  {
    value: 'in',
    label: 'Содержится'
  },
  {
    value: 'includes',
    label: 'Включает'
  },
  {
    value: '=',
    label: '='
  }
]

const numberEquivalenceVariants = [
  {
    value: '<',
    label: '<'
  },
  {
    value: '>',
    label: '>'
  },
  {
    value: '<=',
    label: '<='
  },
  {
    value: '>=',
    label: '>='
  },
  {
    value: '=',
    label: '='
  }
]

const equivalenceChooser = {
  [EColumnType.string]: stringEquivalenceVariants,
  [EColumnType.number]: numberEquivalenceVariants,
  // [EColumnType.date]: numberEquivalenceVariants,
  [EColumnType.flag]: stringEquivalenceVariants,
  [EColumnType.image]: stringEquivalenceVariants
}

export function SearchBar ({
  selectionVariants,
  onPlusClick,
  onMinusClick,
  onSearchClick
}: ISearchBarArgs) {

  if (selectionVariants.length > 0 && selectionVariants[0].type == null) {
    throw new Error('Type of search bar fields must be exists')
  }

  const [selectedField, setSelectedField] = useState(selectionVariants[0])
  const [selectedType, setSelectedType] = useState(selectionVariants[0].type)
  const [selectedEquivalence, setSelectedEauivalence] = useState<TSelectionVariant>(equivalenceChooser[EColumnType.string][0])
  const [textQuery, setTextQuery] = useState('')

  const onTextQueryChange = useCallback((query: string) => { setTextQuery(query) }, [])

  const onSelectionVariantChange = useCallback((selected: TSelectionVariant) => {
    setSelectedField({...selected})
    setSelectedType(selected.type)
  }, [])

  const onEquivalenceChange = useCallback((selected: TSelectionVariant) => {
    setSelectedEauivalence(selected)
  }, [])

  const makeSearchQuery = useCallback(() => {
    return {
      field: selectedField.value,
      equivalence: selectedEquivalence.value,
      value: textQuery
    }
  }, [textQuery, selectedEquivalence, selectedField])

  return <div className={styles.SearchBar}>
        <Paper
            component={'form'}
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
            >
            <SelectionMenu 
              selectionVariants={selectionVariants} 
              onSelect={onSelectionVariantChange}
              selectedValue={selectedField.value}
            />
            <SelectionMenu 
              selectionVariants={equivalenceChooser[selectedType || 0]} 
              onSelect={onEquivalenceChange}
              selectedValue={selectedEquivalence.value}
            />
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={`Поиск по полю '${selectedField.label}'`}
                inputProps={{ 'aria-label': 'search by name' }}
                onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { onTextQueryChange(e.currentTarget.value) }}
            />
            <IconButton type="button" sx={{ p: '16px' }} aria-label="search" onClick={() => { onSearchClick(makeSearchQuery()) }}>
                <SearchIcon />
            </IconButton>
            {(onPlusClick || onMinusClick) && <Divider sx={{ height: '48px', m: 0.5 }} orientation="vertical" />}
            {onPlusClick && <IconButton color="primary" sx={{ p: '16px' }} aria-label="directions" onClick={onPlusClick}>
                <AddIcon />
            </IconButton>}
            {onMinusClick && <IconButton type="button" sx={{ p: '16px' }} aria-label={'minus'} onClick={onMinusClick}>
                <RemoveIcon />
            </IconButton>}
        </Paper>
    </div>
}
