import styles from './styles.module.css'
import { type TPlayer } from '../../../pages/PlayersPage/types'
import { TournamentGridToursTabs } from '../tournament-grid-tours-tabs'
import { TournamentQuantityInfoMessage } from '../tournament-quantity-info-message'
import { TournamentTourCardsList } from '../tournament-tour-cards-list'
import { connect, useDispatch, useSelector } from 'react-redux'
import { type RootState } from '../../../app/store'
import { mapDispatchToProps } from '../../../pages/TournamentsPage/maps'
import { useEffect, useLayoutEffect, useMemo } from 'react'
import { TournamentGridParams } from '../tournament-grid-params/tournament-grid-params'
import { useOnChangeParamsAction } from './hooks/useOnChangeParamsAction'
import { takeBinaryDivideNumber } from '../../../shared/tournaments/procedures'
import { PlayersList } from '../players-list/players-list'
import { setEditableTournamentFields, setTourId } from 'app/store/slices/editableTournament'

const mapStateToProps = (state: RootState) => ({
  activePlayers: state.editableTournamentSlice.activePlayers,
  tours: state.editableTournamentSlice.tours,
  internalPlayers: state.editableTournamentSlice.internalPlayers,
  gridFirstSize: state.editableTournamentSlice.gridFirstSize,
  gridLastSize: state.editableTournamentSlice.gridLastSize,
  manualGridEdition: state.editableTournamentSlice.manualGridEdition,
  isEllimination: state.editableTournamentSlice.isEllimination,
  tourId: state.editableTournamentSlice.selectableParameters.tourId,
})

export const TournamentGridEditor = connect(mapStateToProps, mapDispatchToProps)(function ({
  activePlayers,
  internalPlayers,
  gridFirstSize,
  gridLastSize,
  isEllimination,
  tours,
  tourId,
}: any) {

  const dispatch = useDispatch()

  useEffect(() => {

    if(!tours){
      return
    }

    if(tours.length === 0){
      return
    }

    if( tourId >= tours.length && isEllimination ){
        dispatch(setTourId(0))
    }  
  }, [tourId, tours])

  const permissibleFirstQuantity = (gridFirstSize * 2)
  
  const toursNumberEstimation = takeBinaryDivideNumber(gridFirstSize) - takeBinaryDivideNumber(gridLastSize)

  useOnChangeParamsAction()

  const players = useMemo(() => [...internalPlayers, ...activePlayers], [internalPlayers, activePlayers])

  if(players.length === 0){
    return <div style={{
      marginTop: 30
    }}>В системе нет ни одного игрока. Добавьте игроков в предыдущем меню что бы сетка стала активной</div>
  }

  // if(tours && gridFirstSize > tours[0]?.length){
  //   return <></>
  // }

  return <div className={styles.TournamentGridEditor}>
        <TournamentGridToursTabs
            toursNumberEstimation={toursNumberEstimation}
            permissibleFirstQuantity={permissibleFirstQuantity}
        />
         {isEllimination && <TournamentGridParams />}

            {/* Игроки справа
            <IsOperator>
                <div className={styles.EditorFrame}>
                    <TournamentTourCardsList />
                </div>
            </IsOperator> */}

            {/* <IsAdmin> */}
                <div className={styles.EditorFrame}>
                    <TournamentTourCardsList />
                    <div className={styles.PlayersListContainer}>
                      <PlayersList />
                    </div>
                </div>
            {/* </IsAdmin> */}

    </div>
})
