import { type TSelectionVariant } from '../../entities/SelectionMenu/types'
import { type TColumns } from '../data-grid/types'

export interface ISearchBarArgs {
  selectionVariants: TSelectionVariant[]
  onPlusClick?: () => void
  onMinusClick?: () => void
  onSearchClick: (query: TSearchQuery) => void
}

export enum ESearchQueryEquivalence {
  equals = '=',
  moreThan = '>',
  moreOrEquals = '>=',
  lessThan = '<',
  lessOrEquals = '<=',
  inString = 'in',
  includes = 'includes',
}

export interface ISearchPanelArgs {
  columns: TColumns[]
  onQueryChange: (query: TSearchQuery[]) => void
  className?: string
}

export interface TSearchQuery {
  field: string
  equivalence: string
  value: string
}
