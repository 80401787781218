import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { setEditableTournamentFields, setIsEllimination } from '../../app/store/slices/editableTournament'

export const mapStateToProps = (state: RootState) => state.editableTournamentSlice
export const mapDispatchToProps = (dispatch: ReturnType<typeof useDispatch>) => {
  return {
    setTournamentAttributes: (obj: Object) => dispatch(setEditableTournamentFields(obj)),
    setIsEllimination: (flag: boolean) => dispatch(setIsEllimination(flag))
  }
}
