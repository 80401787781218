import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { setPlayers, setSelectedPlayers } from '../../app/store/slices/player'
import { type TPlayer } from './types'
import { EColumnType, type TColumns } from '../../widgets/data-grid/types'

export const columns: TColumns[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: '180px',
    type: EColumnType.string
  },
  {
    field: 'name',
    headerName: 'Имя игрока',
    width: '160px',
    type: EColumnType.string
  },
  {
    field: 'avatar',
    headerName: 'Фото',
    width: '160px',
    type: EColumnType.image
  },
  {
    field: 'birthDate',
    headerName: 'Дата рождения',
    width: '100px',
    type: EColumnType.date
  },
  {
    field: 'country',
    headerName: 'Страна',
    width: '30px',
    type: EColumnType.flag
  },
  {
    field: 'city',
    headerName: 'Город',
    width: '80px',
    type: EColumnType.string
  },
  {
    field: 'height',
    headerName: 'Рост',
    width: '160px',
    editable: true,
    type: EColumnType.string
  },
  {
    field: 'weight',
    headerName: 'Вес',
    width: '80px',
    type: EColumnType.string
  },
  {
    field: 'plays',
    headerName: 'Ведущая рука',
    width: '100px',
    type: EColumnType.string
  }
]

export function mapStateToProps (state: RootState) {
  return {
    count: state.playerSlice.count,
    players: state.playerSlice.players,
    selectedPlayers: state.playerSlice.selectedPlayers
  }
}

export function mapDispatchToProps (dispatch: ReturnType<typeof useDispatch>) {
  return {
    setPlayers: (players: TPlayer[]) => dispatch(setPlayers(players)),
    setSelectedPlayers: (players: TPlayer[]) => dispatch(setSelectedPlayers(players))
  }
}
