import { type RootState } from '../../app/store'
import { type useDispatch } from 'react-redux'
import { setEditableNews } from 'app/store/slices/news'
import { TNews } from './types'

export const mapStateToProps = (state: RootState) => state.newsSlice
export const mapDispatchToProps = (dispatch: ReturnType<typeof useDispatch>) => {
  return {
    setEditableNews: (news: TNews) => dispatch(setEditableNews(news))
  }
}
