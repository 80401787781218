// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_IconMenu__Rrc87{
    padding: 20px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100px;

}

.styles_IconMenu__Rrc87 > *{
    margin-left: 20px;
}

.styles_IconMenu__Rrc87 > *:first-child {
    margin-left: 0;
}

.styles_RightCommonControl__YSJah {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 50%;
}

.styles_RightCommonControl__YSJah > * {
    margin-left: 20px;
}

.styles_Help__PSvk1, .styles_Logout__goqRy {
    cursor: pointer;
}

.styles_AlertBar__EF6RR{
    width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/widgets/icon-menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;;AAEjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".IconMenu{\n    padding: 20px 0;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    width: 100%;\n    height: 100px;\n\n}\n\n.IconMenu > *{\n    margin-left: 20px;\n}\n\n.IconMenu > *:first-child {\n    margin-left: 0;\n}\n\n.RightCommonControl {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    width: 50%;\n}\n\n.RightCommonControl > * {\n    margin-left: 20px;\n}\n\n.Help, .Logout {\n    cursor: pointer;\n}\n\n.AlertBar{\n    width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconMenu": `styles_IconMenu__Rrc87`,
	"RightCommonControl": `styles_RightCommonControl__YSJah`,
	"Help": `styles_Help__PSvk1`,
	"Logout": `styles_Logout__goqRy`,
	"AlertBar": `styles_AlertBar__EF6RR`
};
export default ___CSS_LOADER_EXPORT___;
