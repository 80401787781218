// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_PerPageSelector__u5P-b {
    letter-spacing: 0.4px;
    line-height: 166%; /* 19.92px */
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/widgets/data-grid/components/PerPageSelector/styles.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB,EAAE,YAAY;IAC/B,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".PerPageSelector {\n    letter-spacing: 0.4px;\n    line-height: 166%; /* 19.92px */\n    font-family: Roboto, serif;\n    font-style: normal;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PerPageSelector": `styles_PerPageSelector__u5P-b`
};
export default ___CSS_LOADER_EXPORT___;
