// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_SearchPanel__VflzA {
    min-height: min-content;
}

.styles_SearchBar__69ov2 {
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/search-bar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".SearchPanel {\n    min-height: min-content;\n}\n\n.SearchBar {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchPanel": `styles_SearchPanel__VflzA`,
	"SearchBar": `styles_SearchBar__69ov2`
};
export default ___CSS_LOADER_EXPORT___;
