import { type IPerPageCountArgs } from './types'
import { NativeSelect } from '@mui/material'
import { type SyntheticEvent } from 'react'

export const PerPageCount = ({ variants, onChange }: IPerPageCountArgs) => (<NativeSelect
        onChange={(e: SyntheticEvent<HTMLSelectElement>) => { onChange(parseInt(e.currentTarget.value)) }}
        sx={{
          width: '43px'
        }}
        defaultValue={variants[0]}
        inputProps={{
          name: 'age',
          id: 'uncontrolled-native'
        }}
    >
        {variants.map(variant => <option key={variant} value={variant}>{variant}</option>)}
    </NativeSelect>)
