import { type ITableHeaderArgs } from './types'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { useCallback } from 'react'

export function TableHeader ({ columns, editable = false }: ITableHeaderArgs) {
  const headerRow = useCallback(() => {
    const cells = columns.map(cell =>
            <TableCell
                key={cell.field}
                align={cell.align}
                sx={{
                  width: cell.width,
                  whiteSpace: 'nowrap',
                  cursor: 'default'
                }}
            >
                {cell.headerName}
            </TableCell>)

    if (editable) {
      cells.push(<TableCell key={'ControlKey'}></TableCell>)
    }

    return cells
  }, [columns])

  return <TableHead>
        <TableRow>
            {headerRow()}
        </TableRow>
    </TableHead>
}
