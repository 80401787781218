import React from 'react'
import './App.css'
import { AuthPage } from './pages/AuthPage'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PlayersPage } from './pages/PlayersPage'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { PlayersListPage } from './pages/PlayersListPage'
import { TournamentsPage } from './pages/TournamentsPage'
import { TournamentsListPage } from './pages/TournamentsListPage'
import { NewsPage } from './pages/NewsPage'
import { NewsListPage } from './pages/NewsListPage'
import { FileStorePage } from './pages/DocsPage'
import { FileStoreListPage } from './pages/DocsListPage'
import { TestPage } from './pages/tests/TestPage'
import { OperatoresPage } from './pages/OperatoresPage'
import { OperatoresListPage } from './pages/OperatoresListPage'
import { SettingsPage } from './pages/SettingsPage'

const router = createBrowserRouter([
  {
    path: '/documents',
    element: <FileStoreListPage />
  },
  {
    path: '/documents/create',
    element: <FileStorePage />
  },
  {
    path: '/news',
    element: <NewsListPage />
  },
  {
    path: '/news/create',
    element: <NewsPage />
  },
  {
    path: '/players/create',
    element: <PlayersPage />
  },
  {
    path: '/players',
    element: <PlayersListPage />
  },
  {
    path: '/operatores/create',
    element: <OperatoresPage />
  },
  {
    path: '/operatores',
    element: <OperatoresListPage />
  },
  {
    path: '/tournaments/create',
    element: <TournamentsPage />
  },
  {
    path: '/tournaments',
    element: <TournamentsListPage />
  },
  {
    path: '/',
    element: <AuthPage />
  },
  {
    path: '/test',
    element: <TestPage />
  },
  {
    path: '/settings',
    element: <SettingsPage />
  }
])

function App () {
  return (
    <div className="App">
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </div>
  )
}

export default App
