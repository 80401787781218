import { AdminMenu } from '../../widgets/admin-menu'
import styles from './styles.module.css'
import { OperatoresCreationForm } from '../../widgets/operatores-creation-form'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectIfNoRefreshToken } from '../../shared/redirects'
import { IconMenu } from '../../widgets/icon-menu'

export function OperatoresPage () {
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    redirectIfNoRefreshToken(navigate)
  }, [])

  return <div className={styles.Page}>
        <AdminMenu />
        <div className={styles.ContentContainer}>
            <div className={styles.FormContainer}>
                <IconMenu
                    onSearchClick={() => { navigate('/operatores') }}
                    onDeleteClick={() => {}}
                    errorMessage={errorMessage}
                />
                <OperatoresCreationForm />
            </div>
        </div>
    </div>
}
