/**
 * Определение процедур записи матча
 * 1) Увеличить счет
 * 2) Переключить подающего
 * 3) Изменить состояние игры
 * 4) Установить победителя гейма
 *
 */

import { current } from '@reduxjs/toolkit'
import { SetRecord } from './set-record/SetRecord'
import {
  EGameStatements,
  EMatchStatuses,
  TGame,
  type TGameOpponent,
  type TGamePlayerId,
  TSet
} from './types'
import { ESetStatements } from './set-record/types'

type TStatementToActionDict = Record<number, (pid: TGamePlayerId) => void>

/**
 * Принципы работы
 * Сущность описывает целиком весь матч (карточку игрока, виджет matchbeats, статистику)
 * Такое описание базируется на формировании стартовой структуры данных для
 */

export function DoubleMatchRecord () {
  let opponents: TGameOpponent[] = []
  let currentSet: ReturnType<typeof SetRecord> = SetRecord()

  const sets: Array<ReturnType<typeof SetRecord>> = [currentSet]

  let statement: EMatchStatuses = EMatchStatuses.undef

  const setOpponents = (extOpponents: TGameOpponent[]) => opponents = extOpponents
  const pushOpponent = (opponent: TGameOpponent) => opponents.push(opponent)

  const setHoldsOpponent = (pid: TGamePlayerId) => {
    currentSet.setHolds(pid)
    currentSet.switchStatementToActive()
    statement = EMatchStatuses.active
  }

  const setActiveMatchAction = (pid: TGamePlayerId) => {
    currentSet.makeAction(pid)

    if (currentSet.getStatement() === ESetStatements.complete) {
      const recentHolds = currentSet.holds
      currentSet = SetRecord()
      currentSet.switchStatementToActive()
      currentSet.setHolds((recentHolds + 1) % 2)
      sets.push(currentSet)
    }
  }

  const statementToActionDict: TStatementToActionDict = {
    /** Установка подающего если это самое начало игры */
    [EMatchStatuses.undef]: setHoldsOpponent, // Initiate match,
    /** Выбор получателя пойнта, проверка на теннисные ситуации, изменение счета */
    [EMatchStatuses.active]: setActiveMatchAction // Make match action
  }

  const makeAction = (pid: TGamePlayerId) => {
    /** Установка подающего если это самое начало игры */
    statementToActionDict[statement](pid)
  }

  return {
    setOpponents,
    pushOpponent,
    makeAction,

    takeSets: () => sets

  }
}
