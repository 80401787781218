// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwoColumnsChooser{
    position: static;
    display: grid;
    grid-template-columns: 10fr 10fr;
}

.TwoColumnsChooser .MuiPaper-root{
    min-width: 80%!important;
    /*max-width: 80%!important;*/
    /*margin: 50px;*/
}

.TwoColumnsChooser .DataGridControlBar{
    grid-template-columns: 30% 20% 20% 20%!important;
}

.TwoColumnsChooser table {
    height: calc(100% - 65px);
}`, "",{"version":3,"sources":["webpack://./src/widgets/two-columns-chooser/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,wBAAwB;IACxB,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".TwoColumnsChooser{\n    position: static;\n    display: grid;\n    grid-template-columns: 10fr 10fr;\n}\n\n.TwoColumnsChooser .MuiPaper-root{\n    min-width: 80%!important;\n    /*max-width: 80%!important;*/\n    /*margin: 50px;*/\n}\n\n.TwoColumnsChooser .DataGridControlBar{\n    grid-template-columns: 30% 20% 20% 20%!important;\n}\n\n.TwoColumnsChooser table {\n    height: calc(100% - 65px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
