// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_SettingsPage__45xRE{
  position: fixed;
  left: 0; top: 0; right: 0; bottom: 0;
  display: grid;
  grid-template-columns: 2fr 12fr;
  overflow: auto;
}

.styles_SS365accountSettings__jtVIc {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.styles_FormRow__NAl52 {
  display: grid;
  grid-template-columns: 2fr 3fr 5fr 2fr;
  margin-top: 10px;
}

.styles_SettingsPage__45xRE input {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid rgba(10, 10, 10, 0.3);
}
.styles_Notification__69Iyz {
  color: rgba(100, 200, 100, 0.8)
}
`, "",{"version":3,"sources":["webpack://./src/pages/SettingsPage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO,EAAE,MAAM,EAAE,QAAQ,EAAE,SAAS;EACpC,aAAa;EACb,+BAA+B;EAC/B,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;AACzC;AACA;EACE;AACF","sourcesContent":[".SettingsPage{\n  position: fixed;\n  left: 0; top: 0; right: 0; bottom: 0;\n  display: grid;\n  grid-template-columns: 2fr 12fr;\n  overflow: auto;\n}\n\n.SS365accountSettings {\n  width: 70%;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 50px;\n}\n.FormRow {\n  display: grid;\n  grid-template-columns: 2fr 3fr 5fr 2fr;\n  margin-top: 10px;\n}\n\n.SettingsPage input {\n  padding: 5px;\n  border-radius: 3px;\n  border: 1px solid rgba(10, 10, 10, 0.3);\n}\n.Notification {\n  color: rgba(100, 200, 100, 0.8)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsPage": `styles_SettingsPage__45xRE`,
	"SS365accountSettings": `styles_SS365accountSettings__jtVIc`,
	"FormRow": `styles_FormRow__NAl52`,
	"Notification": `styles_Notification__69Iyz`
};
export default ___CSS_LOADER_EXPORT___;
