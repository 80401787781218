import styles from '../../styles.module.css'
import { type SyntheticEvent, useCallback } from 'react'
import { disablePropagation } from '../../../../../shared/procedures'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../../../app/store'

interface IIntermediateLineArgs {
  scoreLength: number
  selected: boolean
  onMatchNumberChange?: (matchNumber: number) => void
  selectedMatchNumber?: number
}

export function IntermediateLine ({
  scoreLength,
  selected,
  onMatchNumberChange = () => {},
  selectedMatchNumber
}: IIntermediateLineArgs) {
  const loginOperator = useSelector((state: RootState) => state.loginSlice)
  const admin = loginOperator.role === 'administrator'

  const onMatchClick = useCallback((i: number) => {
    return (e: SyntheticEvent<HTMLDivElement>) => {
      disablePropagation(e)
      onMatchNumberChange(i)
    }
  }, [onMatchNumberChange])

  return <div className={styles.DividerLine}>
        <div className={styles.DividerContainer}>
            <div></div><div></div>
        </div>
        <div className={styles.DividerContainer}>
            <div></div><div></div>
        </div>
        <div className={styles.DividerEnumeration}>
            {Array.from(Array(scoreLength)).map((_, i) => {
              const pointsStyle = classNames([
                styles.ScoreNumber
              ],
              {
                [styles.SelectableElement]: selected && admin,
                [styles.SelectedElement]: selectedMatchNumber === i && admin

              }
              )

              return <div
                    key={i}
                    className={pointsStyle}
                    onClick={onMatchClick(i)}
                >{i + 1}</div>
            })}
        </div>
    </div>
}
